
//////////////////////// CLASSIC BREADCRUMBS ///////////////////////////


.breadcrumb{
	list-style: none;
	.container(fluid, left, 40);
	& li{
		height: inherit;
		display: block;
		& a {
		height: inherit;
		color: @textcolor-overimg;
		}
	}
	
}


//////////////////////// FILTERS BREADCRUMB ///////////////////////////

.filters-breadcrumb{
	display: block;
	min-height: inherit;
	padding-bottom: 0;
	text-align: left;
	color: @font-ultralight-color;
	& .filter-item{
		display: inline-block;
		position: relative;
		.border-radius();
		background-color: fade(@brand-primary, 100%);
		line-height: 35px;
		padding-left: @simple-padding;
		margin: 0 10px 10px 0;
		& p {
			display: inline-block;
			vertical-align: middle;
			margin:0;
		}
		& .filter-label{
			.font-size(14);
			font-weight: 300;
		}
		& .filter-value{
			margin-left: 5px;
			.font-size(15);
			font-weight: bold;
		}
		& .filter-color {
			display: inline-block;
			width: 24px;
			height: 24px;
			vertical-align: middle;
			margin-left: 7px;
			margin-right: 5px;
			border-radius: 12px;
			border: 2px solid @font-ultralight-color;
		}
		& #paraclose{
			display: inline-block;
			position: relative;
			float: none;
			vertical-align: middle;
			margin:0;
			height: 35px;
			font-size: 0;

			&:after{
				color: @font-ultralight-color;
				.font-size(22);
				display: inline-block;
				vertical-align: middle;
				padding-right: 25px;
			}
			&:hover{
				&:after{
					color: @font-light-color;
			}
			}
		}
	}
}

