body.account{
  & .container{
    float: none;
    margin: auto;
    background: transparent;
    & .account-container{
      display: block;
      width: 100%;
      & .separator{
        border-top: 1px solid @brand-second;
      }
      & .form-horizontal {
        max-width: 80%;
        margin: 0 auto;
      }
      & select, input, textarea {
        max-width: 100%;
        background-color: transparent;
        border: 1px solid rgba(53, 53, 53, 0.6);
      }
      & h2{
        font-family:@font-family-base;
        color: @font-darker-color;
      }
      & .btn {
        height: 30px;
        line-height: 30px;
        border: solid 1px @brand-primary !important;
        color: @brand-primary;
        background: transparent !important;
        &:hover{
          color: @font-dark-color;
          background: @brand-primary !important;
          border: 1px solid #fff !important;
        }
      }
    }
    & header{
      & .form-search{display: none;}
    }

  }
  & footer {
    color: @font-ultralight-color;
  }
}