.widget-gallery {
  display: inline-block;
  width: 66%;
  padding:0;
  @media @mobile {
    display: block;
    width: 100%;
  }
  .item {
    @media @xlarge {
      .col(2);
      padding-top: 10px;
    }
    @media @large {
      .col(3);
      padding-top: 10px;
    }
    @media @desktop {
      .col(4);
      padding-top: 10px;
    }
    @media @tablet {
      .col(6);
      padding-top: 10px;
    }
    @media @mobile {
      margin: 10px 0;
      .col(12);
      padding-top: 10px;
    }
    box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.22);
    h4 {
      font-size: 17px;
      line-height: 25px;
      padding: 0;
      font-weight: normal;
      height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 10px;
      a {
        &:hover {
          text-decoration: none;
          color: @brand-primary;
        }
      }
    }
    &.first {
      margin-left: 0
    }
    .framed {
      height: 194px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      a {
        display: block;
        height: 100%;
        @media @mobile {
          border: 7px solid white;
        }
      }
    }
  }
  .stories-more {
    border-top: 1px solid #f2f2f2;
    padding: 30px;
    text-align: center;
    clear:both;
    &:before {
      content: none;
    }
    @media @mobile {
      float: none;
      border-top: 0;
    }
    a {
      float: right;
      color: @font-dark-color;
      font-size: 14px;
      font-weight: bold;
      @media @mobile {
        float: none;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
        border: solid 1px #686868;
        padding: 5px @double-padding;
        &:before {
          content: none;
        }
      }
      &:after {
        content: none;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  &:after {
    content: none;
  }
}