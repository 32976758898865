
.list-not-cropped{
  margin-bottom: @double-padding;
  padding: @double-padding @simple-padding @double-padding @simple-padding;
  > li {
    &:first-child{
      margin-top: 0;
    }      
    .row(12);
    position: relative;
    list-style: none;    
    z-index: 0;
    display: block;
    margin-bottom: @simple-padding;
    @media @xlarge, @large {
        height: @list-item-height + 20px;
        line-height: @list-item-height + 20px;
    };
    height: @list-item-height;
    float: none;
    overflow: hidden;
    box-sizing: border-box;
    padding-left: @item-gutter*2;

    
    & .item-paginate {
      height: @list-item-height - 4px !important;
      display: table;
      padding: @double-padding;
      margin-left: -1px;
      background-color: transparent !important;
      outline: 1px solid @brand-second;

      & a {
        display: table-cell;
        position: relative;
        height: 100%;
        text-align: left;
        vertical-align: middle;
        .font(22);
        color: @brand-second;
         
        &:hover{
          text-decoration: none; 
        }
      }
    }
    
    
    & .item{

      overflow: hidden;
      background-color: #ffffff;
      height: @list-item-height;
      @media @xlarge, @large {
        height: @list-item-height + 20px;
        line-height: @list-item-height + 20px;
      };
      box-sizing: border-box;
      width: 100%;
      border-right: 5px solid transparent;

      & .image {
        
        @media @xlarge, @large {.row(2);};
        @media @desktop, @tablet{.row(3);};
        @media @mobile{.row(5);};
        
        .row(3);
          @media @xlarge, @large {
          height: @list-item-height + 20px;
          line-height: @list-item-height + 20px;
          };
        height: @list-item-height;
        line-height: @list-item-height;
        display: block;
        position: relative;
        background-color: @thumbnail-background-color;
        & a{    
          display: inline-block;
          position: relative;
          cursor: pointer;
          width: 100%;
          height: inherit;
          text-align: center;
            @media @xlarge, @large {
            height: @list-item-height + 20px;
            line-height: @list-item-height + 20px;
            max-height: @list-item-height + 20px;
            };
          max-height: @list-item-height;
          line-height: inherit;
          vertical-align: middle;
          & .icon-only{
            width: 100%;
            height: @list-item-height;
            line-height: inherit;
            background-color: @soft-border-color;
            & span{
              .file-icon-medium();
              display: inline-block;
              vertical-align: middle;
              line-height: inherit;
            }
          }
        }
        & .file-icon-small {
          position: absolute;
          z-index: 2;
          top: 10px;
          right: 10px;
          left: inherit;
        }
      }

      & .caption{
          height:100%;
          .col(6);
          line-height: @list-item-height;
          vertical-align: middle;
            @media @xlarge, @large {
              line-height: @list-item-height + 20px;
            };
          
          .media-title{
                margin: 0;
            & a{
              padding-left: @simple-padding;
              .font(16);
              font-weight: 700;
              color: #373334;
              text-transform: uppercase;
              text-align: left;
              display: inline-block;
              vertical-align: middle;
              @media @mobile {
                .font(12);
              }
            }
          
          display: block;
          float: left;
          vertical-align: middle;
          max-width: 59%;
          overflow: hidden;
          text-overflow: ellipsis;
          @media @xlarge, @large {
              height: @list-item-height + 20px;
              line-height: @list-item-height + 20px;
              max-height: @list-item-height + 20px;
          };
          line-height: @list-item-height;
          max-height: @list-item-height;

          }
          & .extra-fields{
            display: inline-block;
            vertical-align: middle;
            line-height: @list-item-height;
            max-width: 38%;
            margin-left: 15px;
            .font(11);
            & p{margin-bottom: 5px; margin-top: 0;}
          }
      }
      

        & .actions  {
        
        background-color: #ffffff;
        margin-right: 5px;
        .col(3);
        float: right;
        @media @xlarge {.col(3);float: right;display: none;};
        display: none;
        position: absolute;
        right: 0;
        z-index: 99;
          padding: 0;
          @media @xlarge, @large {
          height: @list-item-height + 20px;
          line-height: @list-item-height + 20px;
          };
        height: @list-item-height;
        line-height: @list-item-height;
        & a {
          height: auto;
          line-height: @list-item-height;
          display: inline-block;
          vertical-align: middle;

        }
        & .bnt-wrapper{
          
          height: inherit;
          line-height: @list-item-height;
          display: inline-block;
          vertical-align: middle;
      
             & .item-btn {
              @media @large, @xlarge{ width: 200px;};
              width: 160px;
              margin-top: 5px;
              border: 2px solid @brand-primary;
               @media @mobile{ width: 85%;};
              & p{
                color: @brand-primary;
              }
              &:first-child{margin-top: 0;}
              &:after{display: none;}

            }            
          }
        }
      
      & .selector-container{
        visibility: hidden;
        position: absolute;
        display:block;
        padding: @simple-padding;
        height: 20px;
        line-height: 20px;
      }
    }  
  }
}

// ############### ANIMATIONS & BEHAVIOURS ###############  //
// .list-not-cropped{
//   li {
//       &:hover{
//         z-index: 3;

//         & .item-paginate{
//         outline: 1px solid @brand-primary;
//         border: none !important;
//           & a{
//             color: @brand-primary;
//           }

//         }
//         & .item{
//           // border-bottom: 4px solid @brand-primary;
//           // box-shadow: 0px 0px 10px hsla(0, 0%, 10%, .5);
//           border-right: 5px solid @brand-primary;
//           a {color: @brand-primary;cursor: pointer;}
//           & .caption{
//             & .media-title{
//               & a {
//                 color: @brand-primary;
//               }

//             }
//           }
//           & .actions{
//             display: inline-block;

//           }
//         }
//         .selector-container{
//           visibility: visible;
//           z-index: 3;
//         }
//         .bnt-wrapper{
//             display: block;
//         }
//       }
//     }


//   .selected {
//     border: 1px solid @brand-primary;
//     .selector-container input {
//       .icon-checkbox_on();

//     }
//   }
// }
// ############### ANIMATIONS & BEHAVIOURS ###############  //
.list-not-cropped{
  li{    
    &:hover{
      z-index: 3; 
     
      & .item-paginate{
      outline: 1px solid @brand-primary;
        & a{
          color: @brand-primary;
        }
      }

      & .item{
        border-right: 5px solid @brand-primary;
        & a {
          color: @brand-primary;
          cursor: pointer;
        }

        & .caption{
          & .media-title{
            & a {
              color: @brand-primary;
            }
          }
        }

        & .actions{
          display: inline-block !important;
        }
      }

      .selector-container{
        visibility: visible;
        z-index: 3;
      }

      .bnt-wrapper{
          display: block;   
      }
    }

///END HOVER


    &.selected {
      z-index: 3;

      & .item-paginate{
        outline: 1px solid @brand-primary;
        & a{
          color: @brand-primary;
        }
      }

      & .item{
        border-right: 5px solid @brand-primary;
        & a {
          color: @brand-primary;
          cursor: pointer;
        }

        & .caption{
          & .media-title{
            & a {
              color: @brand-primary;
            }
          }
        }

        & .actions{
          display: none;
        }
      }

      .selector-container{
        visibility: visible;
        z-index: 3;
      }

      .bnt-wrapper{
        display: block;
      }
      & .selector-container{
        visibility: visible;
        z-index: 3;
        
        & input { 
        display: inline-block;
        line-height: 10px; 
        .icon-checkbox_on();
        z-index: 999;
      
          &:before{
            color:#ffffff;    
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            padding: 0;
          }
        }
      }   
    }  
  }
}
