.jumbotron{
	
	.container(fluid, 500);
	display: table;

	// .text-jumbotron(500px);
	.bg-cover(@jumbotron-img, #ffffff);
	@font-over-img();
	// & span{}
	& h1{
		.font(50);
		vertical-align: middle;
		display: table-cell;
		.push(1);
		.pull(1);
	}
	
		
		@media @tablet {
			.container(fluid, 400);
			.text-jumbotron(400px); 

			& span{
				// .cover(400px);
			}		
		}
		@media @mobile {

			.container(fluid, 300);
			.text-jumbotron(200px); 
				// & span{.cover(300px);}
			
		}

  

}