/**
 * Gallery
 */
body.galleries {
  #mygallery {
    display: block;
    width: 100%;
    padding: 0;
  }

  & h2 {
    max-width: initial;
    text-align: left;
  }
  .gallery {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: block;
    float: left;
    padding: 0 @simple-padding;
    background-color: @font-ultralight-color;
  }
  .galleries-container {

  }
  .gallery-thumbnail {
    .main-thumbnail {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 195px;
    }
  }

  .galleries_list {
    display: block;
    width: 100%;
    padding: 0 @simple-padding;
    .list-col {
      .items(@items-default);
      @media @xlarge{.items(@items-large);}
      @media @large{.items(@items-large);}
      @media @desktop{.items(@items-desktop);}
      @media @tablet{.items(@items-tablet);}
      @media @mobile{.items(@items-mobile);}
      position: relative;
      padding: @simple-padding;
    }
  }
  .item {
    a {
      text-decoration: none;
      &:hover {
        h3, strong, span {
          color: @brand-primary;
        }
      }
    }
    h3 {
      font-size: 15px;
      font-family: @font-family-base;
      color: #5E5E5E;
      font-weight: 300;
      padding: 0 0;
      float: left;
      width: 80%;
      height: 60px;
      @media @mobile {
        width: 100%;
      }
    }

    .gallery_counter {
      color: @brand-second-alpha;
      float: right;
      margin-top: 20px;
      font-size: 12px;
      line-height: 2.5rem;
      position: absolute;
      @media @mobile {
        position: absolute;
        right: @simple-padding;
        top: @simple-padding;
        background: rgba(255,255,255,0.8);
        margin-top: 0;
        padding: 0 5px;
      }
      & strong {
        line-height: 12px;
        display: block;
        font-size: 28px;
        text-align: center;
        @media @mobile {
          padding-top: 1rem;
          line-height: 18px;
        }
      }
    }

    ul.mini-squares {
      margin: 0;
      padding: 0;
      list-style: none;
      overflow: hidden;

      li {
        margin: 0;
        width: 33.3333%;
        float: left;
        padding-top: 3px;
        padding-right: 3px;
        height: 70px;
        .square-thumbnail {
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center;
        }
      }
    }
  }
}



// ############################################  JUSTIFY GALLERY OVERWRITE ############################################ //



//  ###  This part is important if you use justifiedGallery to make <figure> placement it override the justifiedGallery Css ####  //
.justified-gallery > figure > a > img {margin: 0 !important;}
.justified-gallery > figure {
  position: absolute;
  z-index: 0;
  overflow: hidden;
  margin: inherit;
  min-width: 150px;
}

//// ##### Captions

.justified-gallery > figure > .caption,
.justified-gallery > figure > .caption {
  // background: url('../img/bgd_alpha_white.png');
  background: rgba(255, 255, 255, 0.85);
  -ms-filter:"progid:DXImageTransform.Microsoft.gradient(startColorstr=#85FFFFFF,endColorstr=#85FFFFFF)";
  padding: 0;
  height: 60px;
  display: table;
  vertical-align: middle;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 15px;

  margin-bottom: -1px;   //// fix an issue on the grid

  & a {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: relative;
    font-size: 14px;
    height: 50px;
    display: block;
    max-width: 80%;
    line-height: 16px;
    text-overflow: ellipsis;
    text-overflow-multiline:ellipsis;
    color: @brand-primary;
  }
  a:hover{text-decoration: none;}

  & div{
    width: 70px;
    bottom: 15px;
    right: -15px;
    position: relative;
    display: inline-block;
    height: 30px;
    float: right;

    button {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      display: inline-block;
      height: 30px;
      float: right;
      background: @brand-primary;
      width: 30px;
      margin-left: 3px;
      padding: 0;
      color: #f1f1f1;
      .glyphicon {display: none;}
      &.btn-parade-download {
        background-position: -31px 0;
      }
      & p {
        display: none;
      }
    }
    & h5 {
      font-weight: normal;
    }
  }
}