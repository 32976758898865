

// ################## Carousel Container ###########################

#carouselWrapper {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 2rem 0;
  @media @mobile {
    padding: 0;
  }
}

.widget-carousel {
  display: block;
  width: 100%;
  @media @mobile {
    margin-bottom: 1rem;
  }
}
& .home {
  & h2 {
    display: block;
    max-width: 400px;
    .font(20);
    color: @font-darker-color;
    border-bottom: 1px solid @brand-primary;
    padding-bottom: 0.5rem;
    margin: @simple-padding 0;
    font-weight: 500;
    text-transform: none;
  }
}
// ################## Carousel [descriptions] style ###########################

[data-carousel-description] {
  height:80px;
  background-color: #11111188;
  -ms-filter:"progid:DXImageTransform.Microsoft.gradient(startColorstr=#70FFFFFF,endColorstr=#70FFFFFF)";
  position: absolute;
  top: 365px;
  z-index: 10;
  margin: 0;
  width: 100%;

  .title{
    display:table;
    height: 70px;
    margin-left: -60px;

    & a{
    display:table-cell;
    color: @font-ultralight-color;
    font-size: 24px;
    text-align: left;
    vertical-align: middle;
    }

    & a:hover{text-decoration: none;}
    }

  .slick-slide{
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  .slick-active{
    opacity: 1; 
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      -webkit-transition: opacity 2s ease;
      -o-transition: opacity 2s ease;
      -moz-transition: opacity 2s ease;
      -ms-transition: opacity 2s ease;
  }
}

.slick-active{
  //margin-left: -15px; // Correct some alignement issues due to Bootsrap grid (quick and dirty)

  & p{
    display: table-cell;
    vertical-align: middle;
    height: 50px;
    max-width: 490px;
    text-overflow: ellipsis;
    overflow: hidden;    
  }
}

.slick-loading .slick-list{
  background: #fff url('../img/ajax-loader.gif') center center no-repeat;
}
// ################## Carousel [images] style ###########################

[data-carousel-image] {
  height: 445px;
  vertical-align: middle;

  .slick-dots li button:before{
    content: '•' !important;
    font-size: 20px;
  }

  .slick-slide{opacity: 0.15; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";margin-left: 2px;}
.slick-active{
  opacity: 1; 
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transition: opacity 2s ease;
    -o-transition: opacity 2s ease;
    -moz-transition: opacity 2s ease;
    -ms-transition: opacity 2s ease;
}

  img {
    display: block;
    min-height: 445px;
    max-height: 445px;
    width: auto;
    height: auto;
  }
  .slick-next, .slick-prev {
    margin-top: 0;
    width: 105px;
    min-width: 50px !important;
    height: 445px;
    display: block;
    z-index: 999;
    transition: width 0.2s ease;
    -webkit-transition: width 0.2s ease;
    -o-transition: width 0.2s ease;
    -moz-transition: width 0.2s ease;
    -ms-transition: width 0.2s ease;
  }
  .slick-next {
    right: 0;
    background-position: 100% 180px;
    background-repeat: no-repeat;
    &:before {
      content: "";
      font-family: inherit;
      display: none;
    }
  }
  .slick-prev {
    left: 0;
    background-position: 0 180px;
    background-repeat: no-repeat;
    &:before {
      content: "";
      font-family: inherit;
      display: none;
    }
  }
  .slick-next{
    @media @mobile, @tablet, @desktop, @large, @xlarge{
      background-image: url('../img/arrow_right.png');
    }
  }
  .slick-prev{
    @media @mobile, @tablet, @desktop, @large, @xlarge{
      background-image: url('../img/arrow_left.png');
    }
  }
}

