.cart-list{
  margin-bottom: @double-padding;
  padding-left: @simple-padding;
  padding-top: @simple-padding;
  > li {
    .row(12);
    position: initial;
    list-style: none;
    z-index: 0;
    display: block;
    height: @list-item-height;
    @media @mobile, @tablet {
      height: @list-item-height *2;
    }
    float: none;
    box-sizing: border-box;
    margin-bottom: 2rem;
    font-family: @font-family-base;
    & .item-paginate {
      height: @list-item-height - 4px !important;
      display: table;
      padding: @double-padding;
      margin-right: 1px;
      background-color: transparent !important;
      outline: 1px solid @brand-primary;
      & a {
        position: relative;
        height: 100%;
        text-align: left;
        vertical-align: middle;
        display: table-cell;
        .font(22);
        color: @brand-primary;

        &:hover{
          text-decoration: none;
        }
      }
    }


    > .item{
      height: @list-item-height;
      @media @mobile, @tablet {
        height: @list-item-height *2;
      }
      box-sizing: border-box;
      // .border-radius();
      border-right: 5px solid transparent;
      width: 100%;
      line-height: @list-item-height;
      background-color: #f4f4f4;
      position: relative;
      &.active {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      }
      &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      }
      & .image {
        width: 180px;
        height: @list-item-height;
        max-height: @list-item-height;
        display: block;
        position: relative;
        background-color: @thumbnail-background-color;
        overflow: hidden;
        float: left;

        > a{
          display: table-cell;
          position: relative;
          cursor: pointer;
          width: inherit;
          height: inherit;
          text-align: center;
          max-height: @list-item-height;
          vertical-align: middle;
          & .portrait{
            & img{
              width: 100%;
              height: auto;
              position: absolute;
              top: 50%;
              -webkit-transform: translate(0%,-50%);
              -ms-transform: translate(0%,-50%);
              transform: translate(0%,-50%);
              left: 0;
            }
          }
          & .icon-only{
            & .file-icon{
              display: none;
              background: transparent;
              background-image:none;
            }
            .icon-Basket();
            &:after{
              .font-size(60);
              margin-top: -30px;
              display: block;
            }
            width: 100%;
            height: @list-item-height;
            padding:25px;
            background-color: @soft-border-color;
            line-height: @list-item-height;
            & span{
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }
      & .file-icon {
        .hide();
      }
      & .caption{
        height:@list-item-height;
        .col(7);
        vertical-align: middle;
        @media @mobile, @tablet {
          padding-left: 0;
          width: 100%;
        }
        .media-title{
          .col(5);
          .font(24);
          text-align: left;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
          float: none;
          display: table-cell;
          height: @list-item-height;
          margin: 0;
          width: 180px;
          max-width: 60%;
          > a{
            .font(17);
            font-weight: bold;
            color: @font-darker-color;
            text-align: left;
            font-family: arial;
          }
        }
        & .extra-fields{
          .font(15);
          color: @font-dark-color;
          display: table-cell;
          text-align: left;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 20px;
          width: 39%;
          @media @mobile, @tablet {
            text-align: right;
          }
          & b {
            line-height: inherit;
          }
        }
      }

      > .actions  {
        display: inline-block;
        margin-right: 5px;
        height: @list-item-height;
        line-height: @list-item-height;
        width: 80px;
        position: absolute;
        right: 0;
        outline: 0;
        @media @mobile, @tablet {
          width: 50px;
        }
        & .bnt-wrapper{

          height: @list-item-height;
          line-height: @list-item-height;
          display: inline-block;
          vertical-align: middle;

          > .item-btn {
            display: block;
            vertical-align: middle;
            width: 110px;
            margin-top: 5px;
            border: 1px solid @brand-primary;
            .border-radius();
            height: 40px;
            padding: 5px;
            &.remove p{
              color: @brand-primary;
              text-transform: none;
              font-weight: bold;
              line-height: 40px;
              font-size: 1.3rem;
              font-size: 13px;
              &:before {
                content: '\e944';
                font-family: hollyglyph !important;
                font-size: 2.2rem;
                float: left;
              }
            }
            &.activation p{
              color: @brand-primary;
              text-transform: none;
              font-weight: bold;
              line-height: 40px;
              font-size: 1.3rem;
              font-size: 13px;
            }
            & p{
              color: @brand-primary;
              text-transform: none;
              font-weight: normal;
              line-height: 40px;
            }
            &:first-child{margin-top: 0;}
            &:after{display: none;}

          }
          > .item-dropdown {
            display: inline-block;
            margin-left: 15px;
            border: 1px solid @brand-primary;
            height: 34px;
            vertical-align: middle;
            line-height: 34px;
            .border-radius(5);
            > a{

              width: 12px;
              height: inherit;
              line-height: inherit;
              vertical-align: middle;
              > .caret {
                color: @brand-primary;
              }
            }
          }
        }
      }
      & .selector-container{
        visibility: hidden;
        position: absolute;
        display:block;
        padding: @simple-padding;
        z-index: 1;
        & .ui-checkbox {
          vertical-align: top;
        }
      }
      & .active-cart{
        height: @list-item-height;
        line-height: @list-item-height;
        display: inline-block;
        vertical-align: middle;
        .col(1.5);
        position: absolute;
        right: 150px;
        @media @mobile, @tablet {
          right: 0;
          left: 0;
          margin: auto;
        }
        & a {
          color: @brand-primary;
        }
        & p{
          display: inline-block;
          height: 33px;
          line-height: 33px;
          vertical-align: middle;
          margin: 0;
          .font-size(13);
          text-align: center;
          border: 1px solid @brand-primary;
          width: 130px;
          color: @brand-primary;
          font-weight: bold;
          .border-radius(20);
          &.active {
            color: @font-ultralight-color;
            background: @brand-primary;
          }
          &:after {
            float: left;
            font-size: 2rem;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}

// ############### ANIMATIONS & BEHAVIOURS ###############  //
.cart-list{
  li{
    &:hover{
      z-index: 3;

      & .item-paginate{
        outline: 1px solid @brand-primary;
        > a{
          color: @brand-primary;
        }
      }

      & .item{
        border-right: 5px solid @brand-primary;
        > a {
          color: @brand-primary;
          cursor: pointer;
        }

        > .caption{
          > .media-title{
            > a {
              color: @brand-primary;
            }
          }
          & .extra-fields {
            & b {
              color: @brand-primary;
            }
          }
        }
        & .active-cart {

        }
        & .actions{

        }

        & .selector-container {
          visibility: visible;
        }
      }
      .bnt-wrapper{
        display: block;
      }
    }

    ///END HOVER


    .selected {
      z-index: 3;
      border-right: 5px solid @brand-primary;
      a {
        color: @brand-primary;
        cursor: pointer;
      }

      & .caption{
        & .media-title{
          & a {
            color: @brand-primary;
          }
        }
        & .extra-fields {
          & b {
            color: @brand-primary;
          }
        }
      }
      & .selector-container{
        visibility: visible;
        z-index: 3;

        & input {
          display: inline-block;
          line-height: 10px;
          .icon-checkbox_on();
          z-index: 999;
          &:before{
            color:#ffffff;
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}