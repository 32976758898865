@footer-height : 50px;
footer {
  display: block;
  .transition(left, 0.5s);
  .font(14);
  color: #dedede;
  background-color: #000;
  text-align: left;
  font-family:@font-family-base;
  height: @footer-height; 
  line-height: @footer-height;
  vertical-align: middle;
  margin-top: 90px;
  position: fixed;
  bottom: 0;
  left: -5px;
  padding-left: 20px;
  padding-right: 20px;
  width: 101%;
/*  &:before {
    content: '';
    display: block;
    width: 100%;
    border-top: 5px solid @font-ultralight-color;
    position: relative;
    top: -6px;
  }*/

  @media @mobile {
    position: relative;
    width: 100%;
    height: @triple-padding;
    line-height: @triple-padding;
    left: 0;
    right: 0;
  }
  .spacer {
    margin: 0 7px;
    display: inline-block;
  }

  a{
    color: #dedede;
    text-decoration: underline;
    display: inline-block;
    margin: 0 10px;
    font-weight: bold;
    &:hover,
    &:focus {
      color: @font-light-color;

    }
  }
  span{
    margin: 0 10px;
    float: left;
    color: #dedede;
    display: inline-block;
  }
}