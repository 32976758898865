.stories-sidebar {
  .item {
    h4 {
      font-size: 18px;
      line-height: 25px;
      margin: 0 0 20px 0;
      padding: 0;
      font-family: @font-family-base;
      height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        &:hover {
          text-decoration: none;
          color: @brand-primary;
        }
      }
    }
    &.first {
      margin-left: 0
    }
    .framed {
      height: 194px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-bottom: 50px;
      a {
        display: block;
        height: 100%;
      }
    }
  }
  .stories-more {
    clear: both;
    border-top: 1px solid #f2f2f2;
    margin-top: 30px;
    padding-top: 37px;
    a {
      float: right;
      color: @brand-primary;
      font-size: 16px;
      font-family: @font-family-base;
      font-weight: bold;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
