.widget-links {
  display: inline-block;
  width: 32%;
  vertical-align: top;
  @media @mobile {
    display: block;
    width: 100%;
    padding-bottom: 60px;
    margin-top: 3rem;
  }
  & ul {
    list-style: none;
    padding:0 @double-padding;
    & li {
      margin-bottom: 2rem;
      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3.5px 0 3.5px 7px;
        border-color: transparent transparent transparent #016eb1;
        margin-right: 1.2rem;
        display: inline-block;
        position: relative;
        bottom: 0.6rem;
        vertical-align: middle;
      }
      & a {
        display: inline-block;
        .font(18);
      }
    }
  }
}