@import "section.library.filters.less";
//////////Layout///////////////
@list-controller-height: 55px;
@list-controller-mobile-height: 55px;

& .filters-and-products{
	display: block;
	width: 100%;
	padding: 0 10px;
	padding-top: @header-height + @list-controller-height + 10px;
	padding-bottom: 130px;
	@media @mobile {
		padding-top: @header-height + @list-controller-height*2 + 10px;

	}
	& h1 {
		display: block;
		.font(26);
		color: @font-darker-color;
		border-bottom: 1px solid @brand-primary;
		text-align: center;
		padding-bottom: 0.5rem;
		font-weight: 600;
		margin: @simple-padding auto;
		width: 80%;
	}

}
& .input-group {
	display: inline-block;
	width: 20%;
	position: relative;
	height: @list-controller-height;
	line-height: @list-controller-height;
	vertical-align: top;
	padding: 0 @simple-padding;
	float: left;
	.transition(width, 0s);
	@media @mobile, @tablet {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		z-index: 1010;
	}
	@media @mobile {
		right: 0;
		left: inherit;
		width: 175px;
	}
	&:focus-within {
		width: 50%;
		.transition(width, 0.5s);
		position: absolute;
		z-index: 1;
		@media @mobile {
			width: 80%;
		}
	}
	& .input-group-btn {
		position: absolute;
		right: @simple-padding;
		top: 0;
		line-height: 55px;
		border-radius: 5px 5px 0 0;
		& .btn-default {
			.icon-search();
			background: @brand-primary;
			margin: 0;
			height: 40px;
			border-radius: 0 5px 5px 0;
			color: @font-ultralight-color;
			padding: 0 10px;
			border: 0;
			@media @mobile {
				border-radius: 5px;
			}
			&:hover{
				background: #e6332a;

			}
		}
	}
	& .form-control {
		width: 100%;
		max-width: inherit;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
		margin: 0;
		display: inline-block;
		vertical-align: middle;
		border-radius: 5px;
		&:active, &:focus {
			background: @font-ultralight-color;
			font-size: 12px;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
			border: 0 !important;
		}
	}
}


//////////BARRE DE BOUTONS///////////////

& .product-header {
	position: fixed;
	margin-top: @header-height;
	height: @list-controller-height;
	width: 100%;
	z-index: 1000;
	display: block;
	padding-bottom: 0;
	background: @font-light-color;
	box-shadow: 0 4px 4px rgba(0, 102, 131, 0.1);
	@media @mobile, @tablet {
		z-index: 1003;
	}
	& .list-controller{
		width: 60%;
		height: @list-controller-height;
		line-height: @list-controller-height;
		display: inline-block;
		vertical-align: top;
		float: right;
		position: relative;
		.font-size(12);
		@media @mobile, @tablet {
			width: 84%;
			float: right;
		}
		@media @mobile {
			width: 100%;
			background: #F7F5F5;
			box-shadow: 0 4px 4px rgba(0, 102, 131, 0.1);
			padding-left: 20px;
		}

			//width: 100%;
			//padding: 0 4px;
			//background: @font-ultralight-color;

		& .right {
			display: inline-block;
			text-align: right;
			float: right;
			padding-right: 10px;
			& .glyphicon-shopping-cart{
				color: @brand-primary;
				display: inline-block;
				vertical-align: middle;
				.icon-Basket();
			}
			.all-carts {
				display: table-cell;
				height: 40px;
				line-height: 40px;
				@media @mobile, @tablet, @desktop {
					font-size: 0;
				}
			}
			& .cart {
				display: table-cell;
				height: 40px;
				line-height: 40px;
				&:hover {
					color: @brand-second;
				}
				& .cart-title{
					@media @mobile, @tablet, @desktop{
						.hide();
					};
					color: @brand-primary;
					.font(0);
					font-weight: bold;
					display: inline-block;
					vertical-align: middle;
					margin-right: @simple-padding;
					text-align: right;
				}
				.cart-item-counter {
					display: inline-block;
					color: @brand-primary;
					.font-size(14);
					line-height: 40px;
					font-weight: bold;
					padding: 0 10px;
				}
				& .glyphicon {
					line-height: 40px;
					vertical-align: top;
				}
			}
		}
		& .page-size-selector {
			display: inline-block;
			.icon-round-unfold_more();
			position: relative;
			vertical-align: top;
			&:after {
				background: @brand-primary;
				color: @font-ultralight-color;
				height: 40px;
				width: 40px;
				display: inline-block;
				position: absolute;
				right: -10px;
				line-height: 40px;
				font-size: 2.5rem;
				text-align: center;
				pointer-events: none;
				border-radius: 0 5px 5px 0;
				@media @mobile {
					border-radius: 5px;
				}
			}
			& select{
				margin: 0;
				.font-size(12);
				background: transparent;
				vertical-align: middle;
				display: inline-block;
				font-family: @client-font-family;
				opacity: 0.75;
				padding-left: 0;
				padding-right: 35px;

				@media @mobile {
					max-width: 20px;
					min-width: inherit;
					padding-right: inherit;
				}
			}
		}
	}
}

& .media-counter {
	margin:0 auto;
	padding: 15px;
	.font-size(12);
	font-style: italic;
	color: @font-ultralight-color;
	display: inline-block;
}

.list-switch{
	display: inline-block;
	float: right;
	list-style: none;
	vertical-align: middle;
	line-height: inherit;
	height: inherit;

	& .label{
		float: left;
		display: inline-block;

		@media @mobile{
			display: none;
		};
		& p{
			.font(12);
			color: @font-base-color;
			display: inline-block;
			vertical-align: middle;
			padding: 0 @simple-padding;
			margin:0;
		}
	}  
	& li {
		display: inline-block;
		height: inherit;

		& a{
			display: inline-block;
			vertical-align: middle;
			height: inherit;
			width: @list-controller-height;
			@media @mobile {
				width: @list-controller-mobile-height;
			}
			text-align: center;
			& span {
				display: inline-block;
				vertical-align: middle;
				line-height: inherit;
				height: inherit;
				font-size: 0;
				&:after{
				.font-size(26);
				display: inline-block;
				vertical-align: middle;
				line-height: inherit;
				}
			}
			
			
		}
		
	}
	& a.active {
		height: 100%;
		& span{
			&:after {
				color:@brand-primary;
			}
		}
		
	}
}

.selection, .cart-block {
	display: inline-block;
	vertical-align: middle;
	line-height: @list-controller-height;
	padding: 0 10px;
	height: @double-padding;
	border-radius: 5px;

	& .dropdown {
		& .ui-checkbox {
			vertical-align: middle;
		}
	    & a {
			display: inline-flex;
			min-width: 20px;
			text-align: center;
			line-height: 40px;
			height: 40px;
			.font-size(12);
			@media @mobile, @tablet {
				font-size: 0;
			}
	    }
		& [data-selected-counter] {
			font-weight: bold;
			@media @mobile, @tablet {
				.font-size(13);
			}
		}
		& .icon-Basket {
			margin-right: 0.5rem;
		}
	}
}
.selection {
	background-color: #eaeaea;
	transition: width 1s;
	max-width: 310px;
	overflow: hidden;
	&[data-block-selection=false] {
		width: 0;
		padding:0;
	}
	&[data-block-selection=true] {
		width: auto;
	}
}

.cart-block {
	background: @font-ultralight-color;
	box-shadow: 0 0 4px rgba(0, 102, 131, 0.2);
	margin: 0 5px;
	& .glyphicon {
		line-height: 40px;
		vertical-align: bottom;
		margin-right: 0.3rem;
	}
}

.switch-to-product-block-list{
	& span{
		.icon-display-grid();
	}
}

.switch-to-product-simple-list{
	& span{
		.icon-display-list();
	}
}


////////// No results ////////

#searchNoresult{
	width: 100%;
	text-align: center;
	padding-top: 40px;
}
