@selection-height: 90px;

body.visual {
  .selection {
    padding-left: 0;
    height: @selection-height;
    line-height: @selection-height;
  }
  & .corporate-container {
    .col(12);
    padding: 0;
    & h1 {
      display: block;
      .font(26);
      color: @font-darker-color;
      border-bottom: 1px solid @font-light-color;
      padding-bottom: 1.2rem;
      margin: @double-padding 0;
      font-weight: 500;
    }
  }
  & .list-controller {
    line-height: @selection-height;
    height: @selection-height;
    & .item {
      line-height: @selection-height;
      height: @selection-height;
      & input {
        line-height: @selection-height;
        height: @selection-height;
      }
      & .dropdown {
        float: left;
      }
    }
  }
  /*ul*/
  .container-visual-docs {
    list-style: none;
    margin: 0;
    padding: 0;
    clear: both;
    /*li*/
    .document {
      color: @gray;
      position: relative;
      height: @selection-height;
      line-height: @selection-height;
      border-bottom: 1px solid @soft-border-color;
      &:first-child {
        border-top: 1px solid @soft-border-color;
      }
      &:last-child {
        border-bottom: none;
      }

      &:hover, &.selected {
        color: @font-ultralight-color;
        background-color: @brand-primary;
        border-color: lighten(@brand-primary, 10%);
        .actions button {
          color: @font-ultralight-color;
        }
        .title p{
          color: @font-ultralight-color;
        }
        & .thumbnail-icon {
          &:after {
            content: '\e91f';
            width: 92px;
            background: rgba(0, 0, 0, 0.6);
            position: absolute;
            top: 15px;
            height: 60px;
            text-align-last: center;
            line-height: 60px;
            font-size: 4rem;
            font-family: hollyglyph;
          }
        }
      }

      input[type="checkbox"] {
        font-size: 16px;
        height: @selection-height;
        margin: 0 8px;
      }

      .selector-container {
        line-height: @selection-height;
        display: inline-block;
        height: @selection-height;
        vertical-align: top;
        padding-left: 14px;
      }
      .thumbnail-icon {
        display: inline-block;
        margin: 0 30px;
        width: 122px;
        padding: 15px;
        height: @selection-height;
        vertical-align: top;
        &:hover {
         ~ .preview-thumbnail {
           display: block;
           position: absolute;
           z-index: 1;
           bottom: -50%;
           left: 30%;
           width: 50%;
           opacity: 1;
            & .background-thumbnail {
              background: #1A1A1A !important;
            }
           & .thumbnail-wrapper {
             background: #1A1A1A;
             border: 2px solid #404040;
           }
           & img {
             padding: 15px;
             height: auto;
             width: inherit;
           }
          }
        }
        & .thumbnail-wrapper {
          height: 100%;
        }
        .file-icon {
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          right: 5px;
          bottom: 5px;
        }

      }

      .title {
        display: inline-block;
        line-height: @selection-height;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        vertical-align: top;
        & p {
          line-height: @selection-height;
          margin: 0;
        }
      }
      .preview-thumbnail {
        .transition(opacity, 1000ms);
        .transition(width, 100ms);
        display: inline-block;
        opacity: 0;
        width: 0;
        position: absolute;
      }
      .actions {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        text-align: right;
        padding: 0;
        line-height: inherit;
        height: 100%;
        // top: 50%;
        // margin-top: -20px;
        button {
          display: inline-block;
          vertical-align: top;
          color: lighten(@gray-light, 20%);
          background-color: transparent;
          &:hover {
            background-color: lighten(@brand-primary, 10%);
          }
          & p {
            display: none;
          }
          border: 0;
          padding: 0;
          width: 60px;
          line-height: @selection-height;
          height: 100%;
        }
      }
    }
  }
  .intro {
    color: @font-base-color;
    font-family: @font-family-base;
    .font(18);
    margin: 40px 0;
    & p {
      margin: 0;
    }
  }
}


.actions-and-products > .actions .btn {
  &:extend(.btn-block);
  max-width: 300px; // Else they can be huuuuge
  font-size: 13px;
  color: @font-base-color;
  // Spacing between buttons
  & + .btn {
    margin-top: 5px;
  }

  text-align: right;
  padding: 16px 16px 14px 16px;

  // Align glyphicons
  .glyphicon {
    float: left;
    font-size: 16px;
  }
}
