
@color-theme-dark: #111111;
@color-theme-dark-opacity: #111111aa;

#toggle {
  .icon-round-toggle_off();
  &[data-toggle-state=visible]{
    .icon-round-toggle_on();
  }
}


html[data-toggle-state=visible] {
  .transition(background-color, 1s);
  .transition(color, 1s);

  background-color: @color-theme-dark;
  color: @font-ultralight-color;

  & .input-group {
    color: @font-ultralight-color;
    & .btn-default {
      border-color: @font-ultralight-color;
    }
  }

  
  & .home {
    & h2 {
      border-color: @font-ultralight-color;
      color: @font-ultralight-color;
    }
  }

  & .basket {
    & .all-carts {
      color: @font-ultralight-color;
    }
    & .cart-actif {
      color: @font-ultralight-color;
      border-color: @font-ultralight-color;
    }
  }

  & .account {
    & .container .account-container {
      color: @font-ultralight-color !important;
      &
      & label, h1, h3, button {
        color: @font-ultralight-color;
      }
    }
  }
  & .navbar {
    border-bottom: 1px solid @brand-primary;
    & ul.navbar-nav li {
      color: @font-light-color;
      border: 0;
      &.active, :hover {
        color: @brand-primary;
        border-color: @brand-primary;
        & a {
          color: @brand-primary;
        }
      }
    }
  }
  & .sticky-counter {
    background: @color-theme-dark;
  }
  & .media-counter {
    background: @color-theme-dark;
  }
  & aside.nav, aside.filters {
    background: lighten(@color-theme-dark, 5%);
    color: @font-ultralight-color;
    border-right: 1px solid @font-ultralight-color;
    border-bottom: 1px solid @font-ultralight-color;
    h4:hover {
      color: @brand-primary;
    }
  }
  & .container-header .search-header {
    border-color: @color-theme-dark;
  }

  .dropdown-menu {
    background-color: @color-theme-dark;
  }
  & .product-header {
    color: @font-ultralight-color;
    background: #111111bb;
    & .list-controller {
      & .page-size-selector select {
        background: @color-theme-dark;
        color: @font-ultralight-color;
      }
    }
  }
  & .product-block-list li .item {
    background: @color-theme-dark;
    & .caption {
      background-color: #ffffff88;
    }
  }

  & .selection {
    color: @font-ultralight-color;
    & .dropdown a {
      color: @font-ultralight-color;
    }
  }
  & .cart-list > li > .item {
    background: @color-theme-dark;
  }
  & .webui-popover {
    background-color: @color-theme-dark;
  }
  footer {
    background-color: @color-theme-dark;
    color: @font-ultralight-color;
    border-top: 1px solid @font-ultralight-color;
    &:before {
      border-color: @color-theme-dark;
    }
  }
}