
.flux{
  & .product-list{
    border-top: 1px solid @soft-border-color;
    border-left: 1px solid @soft-border-color;
    display: table;
  }
}





