@holly-list_burger: "\e900";
@holly-more_rounded: "\e901";
@holly-more: "\e902";
@holly-folder_add: "\e903";
@holly-grid: "\e904";
@holly-search: "\e905";
@holly-zoom_in: "\e906";
@holly-zoom_out: "\e907";
@holly-cart: "\e908";
@holly-command: "\e909";
@holly-download: "\e90a";
@holly-folder: "\e90b";
@holly-share: "\e90c";
@holly-upload: "\e90d";
@holly-avatar: "\e90e";
@holly-fullscreen: "\e90f";
@holly-map: "\e910";
@holly-ring: "\e911";
@holly-rule: "\e912";
@holly-save: "\e913";
@holly-size: "\e914";
@holly-double_bold_left: "\e915";
@holly-double_bold_right: "\e916";
@holly-double_thin_left: "\e917";
@holly-double_thin_right: "\e918";
@holly-mail: "\e919";
@holly-rss: "\e91a";
@holly-link: "\e91b";
@holly-lock: "\e91c";
@holly-new_window: "\e91d";
@holly-send: "\e91e";
@holly-view: "\e91f";
@holly-warning: "\e920";
@holly-check: "\e921";
@holly-checkbox_off: "\e922";
@holly-checkbox_on: "\e923";
@holly-options: "\e924";
@holly-radio_off: "\e925";
@holly-radio_on: "\e926";
@holly-comment: "\e927";
@holly-down_bold: "\e928";
@holly-etc: "\e929";
@holly-left_bold: "\e92a";
@holly-right_bold: "\e92b";
@holly-up_bold: "\e92c";
@holly-close_bold: "\e92d";
@holly-close_thin: "\e92e";
@holly-delete: "\e92f";
@holly-down_thin: "\e930";
@holly-left_thin: "\e931";
@holly-right_thin: "\e932";
@holly-up_thin: "\e933";
@holly-calendar: "\e934";
@holly-rounded_left: "\e935";
@holly-rounded_right: "\e936";
@holly-taquet_both: "\e937";
@holly-taquet_down: "\e938";
@holly-taquet_up: "\e939";
@holly-time: "\e93a";
@holly-history: "\e93b";
@holly-infos: "\e93c";
@holly-pause: "\e93d";
@holly-play: "\e93e";
@holly-sound: "\e93f";
@holly-stats: "\e940";
@holly-edit: "\e941";
@holly-embed: "\e942";
@holly-settings: "\e943";
@holly-trash: "\e944";







@holly-icon-search: "\e913";
@holly-icon-interrogation: "\e936";
@holly-icon-i-information: "\e958";
@holly-icon-baseline-add_box: "\e900";
@holly-icon-pencil: "\e966";
@holly-icon-baseline-chat: "\e901";
@holly-icon-baseline-mms: "\e902";
@holly-icon-baseline-chat_bubble_outline: "\e903";
@holly-icon-baseline-mode_comment: "\e904";
@holly-icon-baseline-chat_bubble: "\e905";
@holly-icon-baseline-add_comment: "\e906";
@holly-icon-bubble-talk: "\e907";
@holly-icon-baseline-add_location: "\e908";
@holly-icon-baseline-add: "\e909";
@holly-icon-baseline-airpanemode_active: "\e90a";
@holly-icon-baseline-airplanemode_inactive: "\e90b";
@holly-icon-baseline-ac_unit: "\e90c";
@holly-icon-baseline-whatshot: "\e90d";
@holly-icon-baseline-all_inclusive: "\e90e";
@holly-icon-display-grid: "\e90f";
@holly-icon-display-list: "\e910";
@holly-icon-baseline-beach_access: "\e911";
@holly-icon-baseline-border_color: "\e912";
@holly-icon-baseline-casino: "\e914";
@holly-icon-baseline-check_box_outline_blank: "\e915";
@holly-icon-baseline-check_box: "\e916";
@holly-icon-baseline-edit_attributes: "\e917";
@holly-icon-baseline-edit_location: "\e918";
@holly-icon-baseline-spellcheck: "\e919";
@holly-icon-letter-arrow: "\e91a";
@holly-icon-baseline-font_download: "\e91b";
@holly-icon-baseline-title: "\e91c";
@holly-icon-baseline-text_fields: "\e91d";
@holly-icon-baseline-indeterminate_check_box: "\e91e";
@holly-icon-baseline-laptop_mac: "\e91f";
@holly-icon-baseline-live_help: "\e920";
@holly-icon-baseline-location_disabled: "\e921";
@holly-icon-baseline-mail_outline: "\e922";
@holly-icon-baseline-mail: "\e923";
@holly-icon-baseline-message: "\e924";
@holly-icon-baseline-mobile_friendly: "\e925";
@holly-icon-baseline-mobile_screen_share: "\e926";
@holly-icon-baseline-person_pin: "\e927";
@holly-icon-loope-less: "\e928";
@holly-icon-loope-more: "\e929";
@holly-icon-baseline-remove_circle_outline: "\e92a";
@holly-icon-baseline-remove_circle: "\e92b";
@holly-icon-baseline-reply_all: "\e92d";
@holly-icon-baseline-reply: "\e92e";
@holly-icon-baseline-subdirectory_arrow_left: "\e92f";
@holly-icon-baseline-subdirectory_arrow_right: "\e930";
@holly-icon-baseline-report_off: "\e931";
@holly-icon-baseline-report: "\e932";
@holly-icon-baseline-smoke_free: "\e933";
@holly-icon-baseline-smoking_rooms: "\e934";
@holly-icon-baseline-spa: "\e935";
@holly-icon-baseline-star_half: "\e937";
@holly-icon-baseline-screen_rotation: "\e938";
@holly-icon-baseline-screen_lock_rotation: "\e939";
@holly-icon-baseline-stay_primary_portrait: "\e93a";
@holly-icon-baseline-storage: "\e93b";
@holly-icon-baseline-textsms: "\e93c";
@holly-icon-baseline-toys: "\e93d";
@holly-icon-baseline-tv: "\e93e";
@holly-icon-baseline-waves: "\e93f";
@holly-icon-baseline-widgets: "\e940";
@holly-icon-calendar-date: "\e941";
@holly-icon-pin: "\e942";
@holly-icon-unpin: "\e943";
@holly-icon-save: "\e944";
@holly-icon-folder: "\e945";
@holly-icon-trash: "\e946";
@holly-icon-Share: "\e947";
@holly-icon-Order: "\e948";
@holly-icon-rectangular: "\e949";
@holly-icon-rectangular-curve: "\e94a";
@holly-icon-circle: "\e94b";
@holly-icon-baseline-fingerprint: "\e94c";
@holly-icon-baseline-touch_app: "\e94d";
@holly-icon-Hand: "\e94e";
@holly-icon-baseline-assignment_ind: "\e94f";
@holly-icon-baseline-person: "\e950";
@holly-icon-baseline-person_add: "\e951";
@holly-icon-baseline-account_box: "\e952";
@holly-icon-baseline-account_circle: "\e953";
@holly-icon-round-how_to_reg: "\e954";
@holly-icon-round-group: "\e955";
@holly-icon-user-three: "\e956";
@holly-icon-user-circle-line: "\e957";
@holly-icon-user-talk: "\e959";
@holly-icon-clean-order: "\e95a";
@holly-icon-comment: "\e95b";
@holly-icon-baseline-dialpad: "\e95c";
@holly-icon-baseline-beenhere: "\e95d";
@holly-icon-baseline-verified_user: "\e95e";
@holly-icon-baseline-done: "\e95f";
@holly-icon-baseline-show_chart: "\e960";
@holly-icon-baseline-snooze: "\e961";
@holly-icon-Calendar: "\e962";
@holly-icon-Basket: "\e963";
@holly-icon-baseline-extension: "\e964";
@holly-icon-Drag-and-drop: "\e965";
@holly-icon-Artboard-2: "\e967";
@holly-icon-baseline-build: "\e968";
@holly-icon-round-expand_less: "\e969";
@holly-icon-round-expand_more: "\e96a";
@holly-icon-baseline-chevron_left: "\e96b";
@holly-icon-double-arrows: "\e92c";
@holly-icon-baseline-chevron_right: "\e96c";
@holly-icon-baseline-play_arrow: "\e96d";
@holly-icon-round-chevron_right: "\e96e";
@holly-icon-round-chevron_left: "\e96f";
@holly-icon-baseline-close: "\e970";
@holly-icon-round-close: "\e971";
@holly-icon-cross-rectangular: "\e972";
@holly-icon-baseline-delete_forever: "\e973";
@holly-icon-baseline-error_outline: "\e974";
@holly-icon-infos: "\e975";
@holly-icon-baseline-expand_less:  "\e976";
@holly-icon-baseline-expand_more: "\e977";
@holly-icon-baseline-find_replace: "\e978";
@holly-icon-baseline-group_add: "\e979";
@holly-icon-baseline-group: "\e97a";
@holly-icon-baseline-hearing: "\e97b";
@holly-icon-baseline-thumb_up: "\e97c";
@holly-icon-baseline-thumbs_up_down: "\e97d";
@holly-icon-round-delete_sweep: "\e97e";
@holly-icon-baseline-restore_from_trash: "\e97f";
@holly-icon-round-library_music: "\e980";
@holly-icon-round-music_note: "\e981";
@holly-icon-round-music_off: "\e982";
@holly-icon-round-person_add_disabled: "\e983";
@holly-icon-round-person_add: "\e984";
@holly-icon-round-playlist_add_check: "\e985";
@holly-icon-round-playlist_add: "\e986";
@holly-icon-round-queue_music: "\e987";
@holly-icon-round-work_off: "\e988";
@holly-icon-round-work_outline:  "\e989";
@holly-icon-round-work: "\e98a";
@holly-icon-round-zoom_in: "\e98b";
@holly-icon-round-zoom_out: "\e98c";
@holly-icon-baseline-alarm_add: "\e98d";
@holly-icon-baseline-alarm_off: "\e98e";
@holly-icon-baseline-alarm_on: "\e98f";
@holly-icon-baseline-alarm: "\e990";
@holly-icon-baseline-blur_off: "\e991";
@holly-icon-baseline-bookmark_border: "\e992";
@holly-icon-baseline-bookmark: "\e993";
@holly-icon-baseline-bookmarks: "\e994";
@holly-icon-baseline-cached: "\e995";
@holly-icon-baseline-class: "\e996";
@holly-icon-baseline-code: "\e997";
@holly-icon-baseline-compare_arrows: "\e998";
@holly-icon-baseline-delete_outline: "\e999";
@holly-icon-baseline-delete: "\e99a";
@holly-icon-baseline-drag_indicator: "\e99b";
@holly-icon-baseline-event: "\e99c";
@holly-icon-baseline-explore_off: "\e99d";
@holly-icon-baseline-explore: "\e99e";
@holly-icon-baseline-favorite_border: "\e99f";
@holly-icon-baseline-favorite: "\e9a0";
@holly-icon-baseline-flip: "\e9a1";
@holly-icon-baseline-history: "\e9a2";
@holly-icon-baseline-hourglass_empty: "\e9a3";
@holly-icon-baseline-info: "\e9a4";
@holly-icon-baseline-linear_scale: "\e9a5";
@holly-icon-baseline-swap_calls: "\e9a6";
@holly-icon-outline-help: "\e9a7";
@holly-icon-outline-info: "\e9a8";
@holly-icon-outline-label: "\e9a9";
@holly-icon-outline-settings_applications: "\e9aa";
@holly-icon-round-add_a_photo: "\e9ab";
@holly-icon-round-add_alert: "\e9ac";
@holly-icon-round-add_box: "\e9ad";
@holly-icon-round-add_circle_outline: "\e9ae";
@holly-icon-round-add_circle: "\e9af";
@holly-icon-round-add_photo_alternate: "\e9b0";
@holly-icon-round-adjust: "\e9b1";
@holly-icon-round-assistant_photo: "\e9b2";
@holly-icon-round-attach_file: "\e9b3";
@holly-icon-round-attachment: "\e9b4";
@holly-icon-round-blur_circular: "\e9b5";
@holly-icon-round-blur_linear: "\e9b6";
@holly-icon-round-blur_off: "\e9b7";
@holly-icon-round-blur_on: "\e9b8";
@holly-icon-round-brush: "\e9b9";
@holly-icon-round-bubble_chart: "\e9ba";
@holly-icon-round-cake: "\e9bb";
@holly-icon-round-call: "\e9bc";
@holly-icon-round-camera_alt: "\e9bd";
@holly-icon-round-camera: "\e9be";
@holly-icon-round-cancel: "\e9bf";
@holly-icon-round-check_box: "\e9c0";
@holly-icon-round-cloud_circle: "\e9c1";
@holly-icon-round-cloud_done: "\e9c2";
@holly-icon-round-cloud_download: "\e9c3";
@holly-icon-round-cloud_off: "\e9c4";
@holly-icon-round-cloud_queue: "\e9c5";
@holly-icon-round-cloud_upload: "\e9c6";
@holly-icon-round-cloud: "\e9c7";
@holly-icon-round-color_lens: "\e9c8";
@holly-icon-round-colorize: "\e9c9";
@holly-icon-round-computer: "\e9ca";
@holly-icon-round-create_new_folder: "\e9cb";
@holly-icon-round-create: "\e9cc";
@holly-icon-round-crop_free: "\e9cd";
@holly-icon-round-crop_original: "\e9ce";
@holly-icon-round-crop_rotate: "\e9cf";
@holly-icon-round-crop: "\e9d0";
@holly-icon-round-error_outline: "\e9d1";
@holly-icon-round-error: "\e9d2";
@holly-icon-round-event_available: "\e9d3";
@holly-icon-round-event_busy: "\e9d4";
@holly-icon-round-event_note: "\e9d5";
@holly-icon-round-exposure: "\e9d6";
@holly-icon-round-fast_forward: "\e9d7";
@holly-icon-round-fast_rewind: "\e9d8";
@holly-icon-round-filter_hdr: "\e9d9";
@holly-icon-round-first_page: "\e9da";
@holly-icon-round-flash_off: "\e9db";
@holly-icon-round-flash_on: "\e9dc";
@holly-icon-round-folder_open: "\e9dd";
@holly-icon-round-folder_shared: "\e9de";
@holly-icon-round-folder_special: "\e9df";
@holly-icon-round-folder: "\e9e0";
@holly-icon-round-format_color_reset: "\e9e1";
@holly-icon-round-forum: "\e9e2";
@holly-icon-round-forward_5: "\e9e3";
@holly-icon-round-forward_10: "\e9e4";
@holly-icon-round-forward_30: "\e9e5";
@holly-icon-round-gesture: "\e9e6";
@holly-icon-round-grade: "\e9e7";
@holly-icon-round-grain-1: "\e9e8";
@holly-icon-round-grain: "\e9e9";
@holly-icon-round-grid_off: "\e9ea";
@holly-icon-round-grid_on: "\e9eb";
@holly-icon-round-group_add: "\e9ec";
@holly-icon-round-help: "\e9ed";
@holly-icon-round-highlight_off: "\e9ee";
@holly-icon-round-home: "\e9ef";
@holly-icon-round-hourglass_empty: "\e9f0";
@holly-icon-round-hourglass_full: "\e9f1";
@holly-icon-round-image_search: "\e9f2";
@holly-icon-round-indeterminate_check_box: "\e9f3";
@holly-icon-round-insert_chart_outlined: "\e9f4";
@holly-icon-round-insert_chart: "\e9f5";
@holly-icon-round-insert_photo: "\e9f6";
@holly-icon-round-invert_colors: "\e9f7";
@holly-icon-round-keyboard_capslock: "\e9f8";
@holly-icon-round-language: "\e9f9";
@holly-icon-round-last_page: "\e9fa";
@holly-icon-round-line_style: "\e9fb";
@holly-icon-round-linear_scale:  "\e9fc";
@holly-icon-round-link_off: "\e9fd";
@holly-icon-round-link: "\e9fe";
@holly-icon-round-list: "\e9ff";
@holly-icon-round-local_movies: "\ea00";
@holly-icon-round-location_off: "\ea01";
@holly-icon-round-location_on: "\ea02";
@holly-icon-round-location_searching: "\ea03";
@holly-icon-round-filter_1: "\ea04";
@holly-icon-round-filter_2: "\ea05";
@holly-icon-round-filter_3: "\ea06";
@holly-icon-round-filter_4: "\ea07";
@holly-icon-round-filter_5: "\ea08";
@holly-icon-round-filter_6: "\ea09";
@holly-icon-round-filter_7: "\ea0a";
@holly-icon-round-filter_8: "\ea0b";
@holly-icon-round-filter_9: "\ea0c";
@holly-icon-round-filter_9_plus: "\ea0d";
@holly-icon-round-looks_one: "\ea0e";
@holly-icon-round-looks_two: "\ea0f";
@holly-icon-round-looks_3: "\ea10";
@holly-icon-round-looks_4: "\ea11";
@holly-icon-round-looks_5: "\ea12";
@holly-icon-round-looks_6: "\ea13";
@holly-icon-round-mail: "\ea14";
@holly-icon-round-mic_none: "\ea15";
@holly-icon-round-mic_off: "\ea16";
@holly-icon-round-mic: "\ea17";
@holly-icon-round-more_horiz: "\ea18";
@holly-icon-round-movie_filter: "\ea19";
@holly-icon-round-movie: "\ea1a";
@holly-icon-round-music_video: "\ea1b";
@holly-icon-round-not_interested: "\ea1c";
@holly-icon-round-offline_bolt: "\ea1d";
@holly-icon-round-opacity: "\ea1e";
@holly-icon-round-pause_circle_filled: "\ea1f";
@holly-icon-round-pause_circle_outline: "\ea20";
@holly-icon-round-pause: "\ea21";
@holly-icon-round-perm_contact_calendar: "\ea22";
@holly-icon-round-perm_media: "\ea23";
@holly-icon-round-perm_phone_msg: "\ea24";
@holly-icon-round-person_pin_circle: "\ea25";
@holly-icon-round-person_pin: "\ea26";
@holly-icon-round-pin_drop: "\ea27";
@holly-icon-round-play_circle_filled_white: "\ea28";
@holly-icon-round-play_circle_outline: "\ea29";
@holly-icon-round-portrait: "\ea2a";
@holly-icon-round-power_settings_new: "\ea2b";
@holly-icon-round-print: "\ea2c";
@holly-icon-round-public: "\ea2d";
@holly-icon-round-query_builder: "\ea2e";
@holly-icon-round-radio_button_checked: "\ea2f";
@holly-icon-round-radio_button_unchecked: "\ea30";
@holly-icon-round-recent_actors: "\ea31";
@holly-icon-round-repeat: "\ea32";
@holly-icon-round-replay_5: "\ea33";
@holly-icon-round-replay_10: "\ea34";
@holly-icon-round-replay_30: "\ea35";
@holly-icon-round-report_problem: "\ea36";
@holly-icon-round-scatter_plot: "\ea37";
@holly-icon-round-send: "\ea38";
@holly-icon-round-settings_applications: "\ea39";
@holly-icon-round-settings_ethernet: "\ea3a";
@holly-icon-round-settings_voice: "\ea3b";
@holly-icon-round-sms_failed: "\ea3c";
@holly-icon-round-sms: "\ea3d";
@holly-icon-round-speaker_notes_off: "\ea3e";
@holly-icon-round-speaker_notes: "\ea3f";
@holly-icon-round-stars: "\ea40";
@holly-icon-round-style: "\ea41";
@holly-icon-round-supervised_user_circle: "\ea42";
@holly-icon-round-swap_horiz: "\ea43";
@holly-icon-round-swap_horizontal_circle: "\ea44";
@holly-icon-round-swap_vert: "\ea45";
@holly-icon-round-swap_vertical_circle: "\ea46";
@holly-icon-round-timeline: "\ea47";
@holly-icon-round-toggle_off: "\ea48";
@holly-icon-round-toggle_on: "\ea49";
@holly-icon-round-translate: "\ea4a";
@holly-icon-round-trending_down: "\ea4b";
@holly-icon-round-trending_up: "\ea4c";
@holly-icon-round-unfold_less: "\ea4d";
@holly-icon-round-unfold_more: "\ea4e";
@holly-icon-round-view_carousel: "\ea4f";
@holly-icon-round-visibility_off: "\ea50";
@holly-icon-round-visibility: "\ea51";
@holly-icon-round-volume_off: "\ea52";
@holly-icon-round-volume_up: "\ea53";
@holly-icon-round-wallpaper: "\ea54";
@holly-icon-round-warning: "\ea55";
@holly-icon-round-watch_later: "\ea56";
@holly-icon-round-youtube_searched_for: "\ea57";
@holly-icon-round-zoom_out_map: "\ea58";
