@import "variables.holly-glyph.less";

@font-face {
  font-family: hollyglyph;
  src: url('../fonts/holly-glyph.eot');
  src: url('../fonts/holly-glyph.eot') format('embedded-opentype'),
  url('../fonts/holly-glyph.ttf') format('truetype'),
  url('../fonts/holly-glyph.woff') format('woff'),
  url('../fonts/holly-glyph.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  color: red;
}

.holly-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: hollyglyph !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;
  text-decoration: none;
  .font-size(20);
  &:hover, &:active, &:focus {
    color: @brand-primary;
    text-decoration: none;
  }
  // /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-list_burger {
  .holly-icon();
  &:after {
    content: @holly-list_burger;
    text-decoration: none;
  }
}

[data-icon="list_burger"] {
  .holly-icon();
  &:after {
    content: @holly-list_burger;
    text-decoration: none;
  }
}

.icon-more_rounded {
  .holly-icon();
  &:after {
    content: @holly-more_rounded;
    text-decoration: none;
  }
}

.icon-more {
  .holly-icon();
  &:after {
    content: @holly-more;
    text-decoration: none;
  }
}

.icon-folder_add {
  .holly-icon();
  &:after {
    content: @holly-folder_add;
    text-decoration: none;
  }
}

.icon-grid {
  .holly-icon();
  &:after {
    content: @holly-grid;
    text-decoration: none;
  }
}

.icon-search {
  .holly-icon();
  &:after {
    content: @holly-search;
    text-decoration: none;
  }
}

.icon-zoom_in {
  .holly-icon();
  &:after {
    content: @holly-zoom_in;
    text-decoration: none;
  }
}

.icon-zoom_out {
  .holly-icon();
  &:after {
    content: @holly-zoom_out;
    text-decoration: none;
  }
}

.icon-cart {
  .holly-icon();
  &:after {
    content: @holly-cart;
    text-decoration: none;
  }
}

.icon-command {
  .holly-icon();
  &:after {
    content: @holly-icon-Order;
    text-decoration: none;
  }
}

.icon-download {
  .holly-icon();
  &:after {
    content: @holly-icon-Artboard-2;
    text-decoration: none;
  }
}

.icon-folder {
  .holly-icon();
  &:after {
    content: @holly-folder;
    text-decoration: none;
  }
}

.icon-share {
  .holly-icon();
  &:after {
    content: @holly-icon-Share;
    text-decoration: none;
  }
}

.icon-upload {
  .holly-icon();
  &:after {
    content: @holly-upload;
    text-decoration: none;
  }
}

.icon-avatar {
  .holly-icon();
  &:after {
    content: @holly-icon-user-circle-line;
    text-decoration: none;
  }
}

.icon-fullscreen {
  .holly-icon();
  &:after {
    content: @holly-fullscreen;
    text-decoration: none;
  }
}

.icon-map {
  .holly-icon();
  &:after {
    content: @holly-map;
    text-decoration: none;
  }
}

.icon-ring {
  .holly-icon();
  &:after {
    content: @holly-ring;
    text-decoration: none;
  }
}

.icon-rule {
  .holly-icon();
  &:after {
    content: @holly-rule;
    text-decoration: none;
  }
}

.icon-save {
  .holly-icon();
  &:after {
    content: @holly-save;
    text-decoration: none;
  }
}

.icon-size {
  .holly-icon();
  &:after {
    content: @holly-size;
    text-decoration: none;
  }
}

.icon-double_bold_left {
  .holly-icon();
  &:after {
    content: @holly-double_bold_left;
    text-decoration: none;
  }
}

.icon-double_bold_right {
  .holly-icon();
  &:after {
    content: @holly-double_bold_right;
    text-decoration: none;
  }
}

.icon-double_thin_left {
  .holly-icon();
  &:after {
    content: @holly-double_thin_left;
    text-decoration: none;
  }
}

.icon-double_thin_right {
  .holly-icon();
  &:after {
    content: @holly-double_thin_right;
    text-decoration: none;
  }
}

.icon-mail {
  .holly-icon();
  &:after {
    content: @holly-mail;
    text-decoration: none;
  }
}

.icon-rss {
  .holly-icon();
  &:after {
    content: @holly-rss;
    text-decoration: none;
  }
}

.icon-link {
  .holly-icon();
  &:after {
    content: @holly-link;
    text-decoration: none;
  }
}

.icon-lock {
  .holly-icon();
  &:after {
    content: @holly-lock;
    text-decoration: none;
  }
}

.icon-new_window {
  .holly-icon();
  &:after {
    content: @holly-new_window;
    text-decoration: none;
  }
}

.icon-send {
  .holly-icon();
  &:after {
    content: @holly-send;
    text-decoration: none;
  }
}

.icon-view {
  .holly-icon();
  &:after {
    content: @holly-view;
    text-decoration: none;
  }
}

.icon-warning {
  .holly-icon();
  &:after {
    content: @holly-warning;
    text-decoration: none;
  }
}

.icon-check {
  .holly-icon();
  &:after {
    content: @holly-check;
    text-decoration: none;
  }
}

.icon-checkbox_off {
  .holly-icon();
  &:before {
    content: @holly-checkbox_off;
    text-decoration: none;
  }
}

.icon-checkbox_on {
  .holly-icon();
  &:before {
    content: @holly-checkbox_on;
    text-decoration: none;
  }
}

.icon-options {
  .holly-icon();
  &:after {
    content: @holly-options;
    text-decoration: none;
  }
}

.icon-radio_off {
  .holly-icon();
  &:after {
    content: @holly-radio_off;
    text-decoration: none;
  }
}

.icon-radio_on {
  .holly-icon();
  &:after {
    content: @holly-radio_on;
    text-decoration: none;
  }
}

.icon-comment {
  .holly-icon();
  &:after {
    content: @holly-comment;
    text-decoration: none;
  }
}

.icon-down_bold {
  .holly-icon();
  &:after {
    content: @holly-down_bold;
    text-decoration: none;
  }
}

.icon-etc {
  .holly-icon();
  &:after {
    content: @holly-etc;
    text-decoration: none;
  }
}

.icon-left_bold {
  .holly-icon();
  &:after {
    content: @holly-left_bold;
    text-decoration: none;
  }
}

.icon-right_bold {
  .holly-icon();
  &:after {
    content: @holly-right_bold;
    text-decoration: none;
  }
}

.icon-up_bold {
  .holly-icon();
  &:after {
    content: @holly-up_bold;
    text-decoration: none;
  }
}

.icon-close_bold {
  .holly-icon();
  &:after {
    content: @holly-close_bold;
    text-decoration: none;
  }
}

.icon-close_thin {
  .holly-icon();
  &:after {
    content: @holly-close_thin;
    text-decoration: none;
  }
}

.icon-delete {
  .holly-icon();
  &:after {
    content: @holly-delete;
    text-decoration: none;
  }
}

.icon-down_thin {
  .holly-icon();
  &:after {
    content: @holly-down_thin;
  }
}

.icon-left_thin {
  .holly-icon();
  &:after {
    content: @holly-left_thin;
  }
}

.icon-right_thin {
  .holly-icon();
  &:after {
    content: @holly-right_thin;
  }
}

.icon-up_thin {
  .holly-icon();
  &:after {
    content: @holly-up_thin;
  }
}

.icon-calendar {
  .holly-icon();
  &:after {
    content: @holly-calendar;
  }
}

.icon-rounded_left {
  .holly-icon();
  &:after {
    content: @holly-rounded_left;
  }
}

.icon-rounded_right {
  .holly-icon();
  &:after {
    content: @holly-rounded_right;
  }
}

.icon-taquet_both {
  .holly-icon();
  &:after {
    content: @holly-taquet_both;
  }
}

.icon-taquet_down {
  .holly-icon();
  &:after {
    content: @holly-taquet_down;
  }
}

.icon-taquet_up {
  .holly-icon();
  &:after {
    content: @holly-taquet_up;
  }
}

.icon-time {
  .holly-icon();
  &:after {
    content: @holly-time;
  }
}

.icon-history {
  .holly-icon();
  &:after {
    content: @holly-history;
  }
}

.icon-infos {
  .holly-icon();
  &:after {
    content: @holly-infos;
  }
}

.icon-pause {
  .holly-icon();
  &:after {
    content: @holly-pause;
  }
}

.icon-play {
  .holly-icon();
  &:after {
    content: @holly-play;
  }
}

.icon-sound {
  .holly-icon();
  &:after {
    content: @holly-sound;
  }
}

.icon-stats {
  .holly-icon();
  &:after {
    content: @holly-stats;
  }
}

.icon-edit {
  .holly-icon();
  &:after {
    content: @holly-icon-pencil;
  }
}

.icon-embed {
  .holly-icon();
  &:after {
    content: @holly-embed;
  }
}

.icon-settings {
  .holly-icon();
  &:after {
    content: @holly-settings;
  }
}




.icon-search {
  .holly-icon();
  &:after {
    content: @holly-icon-search;
    text-decoration: none;
  }
}

.icon-interrogation {
  .holly-icon();
  &:after {
    content: @holly-icon-interrogation;
    text-decoration: none;
  }
}

.icon-i-information {
  .holly-icon();
  &:after {
    content: @holly-icon-i-information;
    text-decoration: none;
  }
}

.icon-baseline-add_box {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-add_box;
    text-decoration: none;
  }
}

.icon-display-list {
  .holly-icon();
  &:after {
    content: @holly-icon-display-list;
    text-decoration: none;
  }
}

.icon-pencil {
  .holly-icon();
  &:after {
    content: @holly-icon-pencil;
    text-decoration: none;
  }
}

.icon-baseline-chat {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-chat;
    text-decoration: none;
  }
}

.icon-baseline-mms {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-mms;
    text-decoration: none;
  }
}

.icon-baseline-chat_bubble_outline {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-chat_bubble_outline;
    text-decoration: none;
  }
}

.icon-baseline-mode_comment {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-mode_comment;
    text-decoration: none;
  }
}

.icon-baseline-chat_bubble {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-chat_bubble;
    text-decoration: none;
  }
}

.icon-baseline-add_comment {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-add_comment;
    text-decoration: none;
  }
}

.icon-bubble-talk {
  .holly-icon();
  &:after {
    content: @holly-icon-bubble-talk;
    text-decoration: none;
  }
}

.icon-baseline-add_location {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-add_location;
    text-decoration: none;
  }
}

.icon-baseline-add {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-add;
    text-decoration: none;
  }
}

.icon-baseline-airpanemode_active {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-airpanemode_active;
    text-decoration: none;
  }
}

.icon-baseline-airplanemode_inactive {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-airplanemode_inactive;
    text-decoration: none;
  }
}

.icon-baseline-ac_unit {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-ac_unit;
    text-decoration: none;
  }
}

.icon-baseline-whatshot {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-whatshot;
    text-decoration: none;
  }
}

.icon-baseline-all_inclusive {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-all_inclusive;
    text-decoration: none;
  }
}

.icon-baseline-all_inclusive {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-all_inclusive;
    text-decoration: none;
  }
}

.icon-display-grid {
  .holly-icon();
  &:after {
    content: @holly-icon-display-grid;
    text-decoration: none;
  }
}

.icon-baseline-beach_access {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-beach_access;
    text-decoration: none;
  }
}

.icon-baseline-border_color {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-border_color;
    text-decoration: none;
  }
}

.icon-baseline-casino {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-casino;
    text-decoration: none;
  }
}

.icon-baseline-check_box_outline_blank {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-check_box_outline_blank;
    text-decoration: none;
  }
}

.icon-baseline-check_box {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-check_box;
    text-decoration: none;
  }
}

.icon-baseline-edit_attributes {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-edit_attributes;
    text-decoration: none;
  }
}

.icon-baseline-edit_location {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-edit_location;
    text-decoration: none;
  }
}

.icon-baseline-spellcheck {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-spellcheck;
    text-decoration: none;
  }
}

.icon-letter-arrow {
  .holly-icon();
  &:after {
    content: @holly-icon-letter-arrow;
    text-decoration: none;
  }
}

.icon-baseline-font_download {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-font_download;
    text-decoration: none;
  }
}

.icon-baseline-title {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-title;
    text-decoration: none;
  }
}

.icon-baseline-text_fields {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-text_fields;
    text-decoration: none;
  }
}

.icon-baseline-indeterminate_check_box {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-indeterminate_check_box;
    text-decoration: none;
  }
}

.icon-baseline-laptop_mac {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-laptop_mac;
    text-decoration: none;
  }
}

.icon-baseline-live_help {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-live_help;
    text-decoration: none;
  }
}

.icon-baseline-location_disabled {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-location_disabled;
    text-decoration: none;
  }
}

.icon-baseline-mail_outline {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-mail_outline;
    text-decoration: none;
  }
}

.icon-baseline-mail {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-mail;
    text-decoration: none;
  }
}

.icon-baseline-message {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-message;
    text-decoration: none;
  }
}

.icon-baseline-mobile_friendly {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-mobile_friendly;
    text-decoration: none;
  }
}

.icon-baseline-mobile_screen_share {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-mobile_screen_share;
    text-decoration: none;
  }
}

.icon-baseline-person_pin {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-person_pin;
    text-decoration: none;
  }
}

.icon-loope-less {
  .holly-icon();
  &:after {
    content: @holly-icon-loope-less;
    text-decoration: none;
  }
}

.icon-loope-more {
  .holly-icon();
  &:after {
    content: @holly-icon-loope-more;
    text-decoration: none;
  }
}

.icon-baseline-remove_circle_outline {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-remove_circle_outline;
    text-decoration: none;
  }
}

.icon-baseline-remove_circle {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-remove_circle;
    text-decoration: none;
  }
}

.icon-baseline-reply_all {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-reply_all;
    text-decoration: none;
  }
}

.icon-baseline-reply {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-reply;
    text-decoration: none;
  }
}

.icon-baseline-subdirectory_arrow_left {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-subdirectory_arrow_left;
    text-decoration: none;
  }
}

.icon-baseline-subdirectory_arrow_right {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-subdirectory_arrow_right;
    text-decoration: none;
  }
}

.icon-baseline-report_off {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-report_off;
    text-decoration: none;
  }
}

.icon-baseline-report {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-report;
    text-decoration: none;
  }
}

.icon-baseline-smoke_free {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-smoke_free;
    text-decoration: none;
  }
}

.icon-baseline-smoking_rooms {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-smoking_rooms;
    text-decoration: none;
  }
}

.icon-baseline-spa {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-spa;
    text-decoration: none;
  }
}

.icon-baseline-star_half {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-star_half;
    text-decoration: none;
  }
}

.icon-baseline-screen_rotation {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-screen_rotation;
    text-decoration: none;
  }
}

.icon-baseline-screen_lock_rotation {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-screen_lock_rotation;
    text-decoration: none;
  }
}

.icon-baseline-stay_primary_portrait {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-stay_primary_portrait;
    text-decoration: none;
  }
}

.icon-baseline-storage {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-storage;
    text-decoration: none;
  }
}

.icon-baseline-textsms {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-textsms;
    text-decoration: none;
  }
}

.icon-baseline-textsms {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-textsms;
    text-decoration: none;
  }
}

.icon-baseline-tv {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-tv;
    text-decoration: none;
  }
}

.icon-baseline-waves {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-waves;
    text-decoration: none;
  }
}

.icon-baseline-widgets {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-widgets;
    text-decoration: none;
  }
}

.icon-calendar-date {
  .holly-icon();
  &:after {
    content: @holly-icon-calendar-date;
    text-decoration: none;
  }
}

.icon-pin {
  .holly-icon();
  &:after {
    content: @holly-icon-pin;
    text-decoration: none;
  }
}

.icon-unpin {
  .holly-icon();
  &:after {
    content: @holly-icon-unpin;
    text-decoration: none;
  }
}

.icon-save {
  .holly-icon();
  &:after {
    content: @holly-icon-save;
    text-decoration: none;
  }
}

.icon-folder{
  .holly-icon();
  &:after {
    content: @holly-icon-folder;
    text-decoration: none;
  }
}

.icon-trash {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-delete_outline;
    text-decoration: none;
  }
}

.icon-Share {
  .holly-icon();
  &:after {
    content: @holly-icon-Share;
    text-decoration: none;
  }
}

.icon-Order {
  .holly-icon();
  &:after {
    content: @holly-icon-Order;
    text-decoration: none;
  }
}

.icon-rectangular {
  .holly-icon();
  &:after {
    content: @holly-icon-rectangular;
    text-decoration: none;
  }
}

.icon-rectangular-curve {
  .holly-icon();
  &:after {
    content: @holly-icon-rectangular-curve;
    text-decoration: none;
  }
}

.icon-circle {
  .holly-icon();
  &:after {
    content: @holly-icon-circle;
    text-decoration: none;
  }
}

.icon-baseline-fingerprint {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-fingerprint;
    text-decoration: none;
  }
}

.icon-baseline-touch_app {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-touch_app;
    text-decoration: none;
  }
}

.icon-Hand {
  .holly-icon();
  &:after {
    content: @holly-icon-Hand;
    text-decoration: none;
  }
}

.icon-baseline-assignment_ind {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-assignment_ind;
    text-decoration: none;
  }
}

.icon-baseline-person {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-person;
    text-decoration: none;
  }
}

.icon-baseline-person_add {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-person_add;
    text-decoration: none;
  }
}

.icon-baseline-account_box {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-account_box;
    text-decoration: none;
  }
}

.icon-baseline-account_circle {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-account_circle;
    text-decoration: none;
  }
}

.icon-round-how_to_reg {
  .holly-icon();
  &:after {
    content: @holly-icon-round-how_to_reg;
    text-decoration: none;
  }
}

.icon-round-group {
  .holly-icon();
  &:after {
    content: @holly-icon-round-group;
    text-decoration: none;
  }
}

.icon-user-three {
  .holly-icon();
  &:after {
    content: @holly-icon-user-three;
    text-decoration: none;
  }
}

.icon-user-circle-line {
  .holly-icon();
  &:after {
    content: @holly-icon-user-circle-line;
    text-decoration: none;
  }
}

.icon-user-talk {
  .holly-icon();
  &:after {
    content: @holly-icon-user-talk;
    text-decoration: none;
  }
}

.icon-clean-order {
  .holly-icon();
  &:after {
    content: @holly-icon-clean-order;
    text-decoration: none;
  }
}

.icon-comment {
  .holly-icon();
  &:after {
    content: @holly-icon-comment;
    text-decoration: none;
  }
}

.icon-baseline-dialpad {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-dialpad;
    text-decoration: none;
  }
}

.icon-baseline-beenhere {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-beenhere;
    text-decoration: none;
  }
}

.icon-baseline-verified_user {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-verified_user;
    text-decoration: none;
  }
}

.icon-baseline-done {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-done;
    text-decoration: none;
  }
}

.icon-baseline-show_chart {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-show_chart;
    text-decoration: none;
  }
}

.icon-baseline-snooze {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-snooze;
    text-decoration: none;
  }
}

.icon-Calendar {
  .holly-icon();
  &:after {
    content: @holly-icon-Calendar;
    text-decoration: none;
  }
}

.icon-Basket {
  .holly-icon();
  &:after {
    content: @holly-icon-Basket;
    text-decoration: none;
  }
}

.icon-baseline-extension {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-extension;
    text-decoration: none;
  }
}

.icon-Drag-and-drop {
  .holly-icon();
  &:after {
    content: @holly-icon-Drag-and-drop;
    text-decoration: none;
  }
}

.icon-Artboard-2 {
  .holly-icon();
  &:after {
    content: @holly-icon-Artboard-2;
    text-decoration: none;
  }
}

.icon-baseline-build {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-build;
    text-decoration: none;
  }
}

.icon-round-expand_less {
  .holly-icon();
  &:after {
    content: @holly-icon-round-expand_less;
    text-decoration: none;
  }
}

.icon-round-expand_more {
  .holly-icon();
  &:after {
    content: @holly-icon-round-expand_more;
    text-decoration: none;
  }
}

.icon-baseline-chevron_left {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-chevron_left;
    text-decoration: none;
  }
}

.icon-double-arrows {
  .holly-icon();
  &:after {
    content: @holly-icon-double-arrows;
    text-decoration: none;
  }
}

.icon-baseline-chevron_right {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-chevron_right;
    text-decoration: none;
  }
}

.icon-baseline-play_arrow {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-play_arrow;
    text-decoration: none;
  }
}

.icon-round-chevron_right {
  .holly-icon();
  &:after {
    content: @holly-icon-round-chevron_right;
    text-decoration: none;
  }
}

.icon-round-chevron_left {
  .holly-icon();
  &:after {
    content: @holly-icon-round-chevron_left;
    text-decoration: none;
  }
}

.icon-baseline-close {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-close;
    text-decoration: none;
  }
}

.icon-round-close {
  .holly-icon();
  &:after {
    content: @holly-icon-round-close;
    text-decoration: none;
  }
}

.icon-cross-rectangular {
  .holly-icon();
  &:after {
    content: @holly-icon-cross-rectangular;
    text-decoration: none;
  }
}

.icon-baseline-delete_forever {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-delete_forever;
    text-decoration: none;
  }
}

.icon-baseline-error_outline {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-error_outline;
    text-decoration: none;
  }
}

.icon-infos {
  .holly-icon();
  &:after {
    content: @holly-icon-infos;
    text-decoration: none;
  }
}

.icon-baseline-expand_less {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-expand_less;
    text-decoration: none;
  }
}

.icon-baseline-expand_more {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-expand_more;
    text-decoration: none;
  }
}

.icon-baseline-find_replace {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-find_replace;
    text-decoration: none;
  }
}

.icon-baseline-group_add {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-group_add;
    text-decoration: none;
  }
}

.icon-baseline-group {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-group;
    text-decoration: none;
  }
}

.icon-baseline-hearing {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-hearing;
    text-decoration: none;
  }
}

.icon-baseline-thumb_up {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-thumb_up;
    text-decoration: none;
  }
}

.icon-baseline-thumbs_up_down {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-thumbs_up_down;
    text-decoration: none;
  }
}

.icon-round-delete_sweep {
  .holly-icon();
  &:after {
    content: @holly-icon-round-delete_sweep;
    text-decoration: none;
  }
}

.icon-baseline-restore_from_trash {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-restore_from_trash;
    text-decoration: none;
  }
}

.icon-round-library_music {
  .holly-icon();
  &:after {
    content: @holly-icon-round-library_music;
    text-decoration: none;
  }
}

.icon-round-music_note {
  .holly-icon();
  &:after {
    content: @holly-icon-round-music_note;
    text-decoration: none;
  }
}

.icon-round-music_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-music_off;
    text-decoration: none;
  }
}

.icon-round-person_add_disabled {
  .holly-icon();
  &:after {
    content: @holly-icon-round-person_add_disabled;
    text-decoration: none;
  }
}

.icon-round-person_add {
  .holly-icon();
  &:after {
    content: @holly-icon-round-person_add;
    text-decoration: none;
  }
}

.icon-round-playlist_add_check {
  .holly-icon();
  &:after {
    content: @holly-icon-round-playlist_add_check;
    text-decoration: none;
  }
}

.icon-round-playlist_add {
  .holly-icon();
  &:after {
    content: @holly-icon-round-playlist_add;
    text-decoration: none;
  }
}

.icon-round-queue_music {
  .holly-icon();
  &:after {
    content: @holly-icon-round-queue_music;
    text-decoration: none;
  }
}

.icon-round-work_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-work_off;
    text-decoration: none;
  }
}

.icon-round-work_outline {
  .holly-icon();
  &:after {
    content: @holly-icon-round-work_outline;
    text-decoration: none;
  }
}

.icon-round-work {
  .holly-icon();
  &:after {
    content: @holly-icon-round-work;
    text-decoration: none;
  }
}

.icon-round-zoom_in {
  .holly-icon();
  &:after {
    content: @holly-icon-round-zoom_in;
    text-decoration: none;
  }
}

.icon-round-zoom_out {
  .holly-icon();
  &:after {
    content: @holly-icon-round-zoom_out;
    text-decoration: none;
  }
}

.icon-baseline-alarm_add {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-alarm_add;
    text-decoration: none;
  }
}

.icon-baseline-alarm_off {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-alarm_off;
    text-decoration: none;
  }
}

.icon-baseline-alarm_on {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-alarm_on;
    text-decoration: none;
  }
}

.icon-baseline-alarm {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-alarm;
    text-decoration: none;
  }
}

.icon-baseline-blur_off {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-blur_off;
    text-decoration: none;
  }
}

.icon-baseline-bookmark_border {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-bookmark_border;
    text-decoration: none;
  }
}

.icon-baseline-bookmark {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-bookmark;
    text-decoration: none;
  }
}

.icon-baseline-bookmarks {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-bookmarks;
    text-decoration: none;
  }
}

.icon-baseline-cached {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-cached;
    text-decoration: none;
  }
}

.icon-baseline-class {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-class;
    text-decoration: none;
  }
}

.icon-baseline-code {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-code;
    text-decoration: none;
  }
}

.icon-baseline-compare_arrows {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-compare_arrows;
    text-decoration: none;
  }
}

.icon-baseline-delete_outline {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-delete_outline;
    text-decoration: none;
  }
}

.icon-baseline-delete {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-delete;
    text-decoration: none;
  }
}

.icon-baseline-drag_indicator {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-drag_indicator;
    text-decoration: none;
  }
}

.icon-baseline-event {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-event;
    text-decoration: none;
  }
}

.icon-baseline-explore_off {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-explore_off;
    text-decoration: none;
  }
}

.icon-baseline-explore {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-explore;
    text-decoration: none;
  }
}

.icon-baseline-favorite_border {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-favorite_border;
    text-decoration: none;
  }
}

.icon-baseline-favorite {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-favorite;
    text-decoration: none;
  }
}

.icon-baseline-flip {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-flip;
    text-decoration: none;
  }
}

.icon-baseline-history {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-history;
    text-decoration: none;
  }
}

.icon-baseline-hourglass_empty {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-hourglass_empty;
    text-decoration: none;
  }
}

.icon-baseline-info {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-info;
    text-decoration: none;
  }
}

.icon-baseline-linear_scale {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-linear_scale;
    text-decoration: none;
  }
}

.icon-baseline-swap_calls {
  .holly-icon();
  &:after {
    content: @holly-icon-baseline-swap_calls;
    text-decoration: none;
  }
}

.icon-outline-help {
  .holly-icon();
  &:after {
    content: @holly-icon-outline-help;
    text-decoration: none;
  }
}

.icon-outline-info {
  .holly-icon();
  &:after {
    content: @holly-icon-outline-info;
    text-decoration: none;
  }
}

.icon-outline-label {
  .holly-icon();
  &:after {
    content: @holly-icon-outline-label;
    text-decoration: none;
  }
}

.icon-outline-settings_applications {
  .holly-icon();
  &:after {
    content: @holly-icon-outline-settings_applications;
    text-decoration: none;
  }
}

.icon-round-add_a_photo {
  .holly-icon();
  &:after {
    content: @holly-icon-round-add_a_photo;
    text-decoration: none;
  }
}

.icon-round-add_alert {
  .holly-icon();
  &:after {
    content: @holly-icon-round-add_alert;
    text-decoration: none;
  }
}

.icon-round-add_box {
  .holly-icon();
  &:after {
    content: @holly-icon-round-add_box;
    text-decoration: none;
  }
}

.icon-add_circle_outline {
  .holly-icon();
  &:after {
    content: @holly-icon-round-add_circle_outline;
    text-decoration: none;
  }
}

.icon-round-add_circle {
  .holly-icon();
  &:after {
    content: @holly-icon-round-add_circle;
    text-decoration: none;
  }
}

.icon-round-add_photo_alternate {
  .holly-icon();
  &:after {
    content: @holly-icon-round-add_photo_alternate;
    text-decoration: none;
  }
}

.icon-round-adjust {
  .holly-icon();
  &:after {
    content: @holly-icon-round-adjust;
    text-decoration: none;
  }
}

.icon-round-assistant_photo {
  .holly-icon();
  &:after {
    content: @holly-icon-round-assistant_photo;
    text-decoration: none;
  }
}

.icon-round-attach_file {
  .holly-icon();
  &:after {
    content: @holly-icon-round-attach_file;
    text-decoration: none;
  }
}

.icon-round-attachment {
  .holly-icon();
  &:after {
    content: @holly-icon-round-attachment;
    text-decoration: none;
  }
}

.icon-round-blur_circular {
  .holly-icon();
  &:after {
    content: @holly-icon-round-blur_circular;
    text-decoration: none;
  }
}

.icon-round-blur_linear {
  .holly-icon();
  &:after {
    content: @holly-icon-round-blur_linear;
    text-decoration: none;
  }
}

.icon-round-blur_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-blur_off;
    text-decoration: none;
  }
}

.icon-round-blur_on {
  .holly-icon();
  &:after {
    content: @holly-icon-round-blur_on;
    text-decoration: none;
  }
}

.icon-round-brush {
  .holly-icon();
  &:after {
    content: @holly-icon-round-brush;
    text-decoration: none;
  }
}

.icon-round-bubble_chart {
  .holly-icon();
  &:after {
    content: @holly-icon-round-bubble_chart;
    text-decoration: none;
  }
}

.icon-round-cake {
  .holly-icon();
  &:after {
    content: @holly-icon-round-cake;
    text-decoration: none;
  }
}

.icon-round-call {
  .holly-icon();
  &:after {
    content: @holly-icon-round-call;
    text-decoration: none;
  }
}

.icon-round-camera_alt {
  .holly-icon();
  &:after {
    content: @holly-icon-round-camera_alt;
    text-decoration: none;
  }
}

.icon-round-camera {
  .holly-icon();
  &:after {
    content: @holly-icon-round-camera;
    text-decoration: none;
  }
}

.icon-round-cancel {
  .holly-icon();
  &:after {
    content: @holly-icon-round-cancel;
    text-decoration: none;
  }
}

.icon-round-check_box {
  .holly-icon();
  &:after {
    content: @holly-icon-round-check_box;
    text-decoration: none;
  }
}

.icon-round-cloud_circle {
  .holly-icon();
  &:after {
    content: @holly-icon-round-cloud_circle;
    text-decoration: none;
  }
}

.icon-round-cloud_done {
  .holly-icon();
  &:after {
    content: @holly-icon-round-cloud_done;
    text-decoration: none;
  }
}

.icon-round-cloud_download {
  .holly-icon();
  &:after {
    content: @holly-icon-round-cloud_download;
    text-decoration: none;
  }
}

.icon-round-cloud_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-cloud_off;
    text-decoration: none;
  }
}

.icon-round-cloud_queue {
  .holly-icon();
  &:after {
    content: @holly-icon-round-cloud_queue;
    text-decoration: none;
  }
}

.icon-round-cloud_upload {
  .holly-icon();
  &:after {
    content: @holly-icon-round-cloud_upload;
    text-decoration: none;
  }
}

.icon-round-cloud {
  .holly-icon();
  &:after {
    content: @holly-icon-round-cloud;
    text-decoration: none;
  }
}

.icon-round-color_lens {
  .holly-icon();
  &:after {
    content: @holly-icon-round-color_lens;
    text-decoration: none;
  }
}

.icon-round-colorize {
  .holly-icon();
  &:after {
    content: @holly-icon-round-colorize;
    text-decoration: none;
  }
}

.icon-round-computer {
  .holly-icon();
  &:after {
    content: @holly-icon-round-computer;
    text-decoration: none;
  }
}

.icon-round-create_new_folder {
  .holly-icon();
  &:after {
    content: @holly-icon-round-create_new_folder;
    text-decoration: none;
  }
}

.icon-round-create {
  .holly-icon();
  &:after {
    content: @holly-icon-round-create;
    text-decoration: none;
  }
}

.icon-round-crop_free {
  .holly-icon();
  &:after {
    content: @holly-icon-round-crop_free;
    text-decoration: none;
  }
}

.icon-round-crop_original {
  .holly-icon();
  &:after {
    content: @holly-icon-round-crop_original;
    text-decoration: none;
  }
}

.icon-round-crop_rotate {
  .holly-icon();
  &:after {
    content: @holly-icon-round-crop_rotate;
    text-decoration: none;
  }
}

.icon-round-crop {
  .holly-icon();
  &:after {
    content: @holly-icon-round-crop;
    text-decoration: none;
  }
}

.icon-round-error_outline {
  .holly-icon();
  &:after {
    content: @holly-icon-round-error_outline;
    text-decoration: none;
  }
}

.icon-round-error {
  .holly-icon();
  &:after {
    content: @holly-icon-round-error;
    text-decoration: none;
  }
}

.icon-round-event_available {
  .holly-icon();
  &:after {
    content: @holly-icon-round-event_available;
    text-decoration: none;
  }
}

.icon-round-event_busy {
  .holly-icon();
  &:after {
    content: @holly-icon-round-event_busy;
    text-decoration: none;
  }
}

.icon-round-event_note {
  .holly-icon();
  &:after {
    content: @holly-icon-round-event_note;
    text-decoration: none;
  }
}

.icon-round-exposure {
  .holly-icon();
  &:after {
    content: @holly-icon-round-exposure;
    text-decoration: none;
  }
}

.icon-round-fast_forward {
  .holly-icon();
  &:after {
    content: @holly-icon-round-fast_forward;
    text-decoration: none;
  }
}

.icon-round-fast_rewind {
  .holly-icon();
  &:after {
    content: @holly-icon-round-fast_rewind;
    text-decoration: none;
  }
}

.icon-round-filter_hdr {
  .holly-icon();
  &:after {
    content: @holly-icon-round-filter_hdr;
    text-decoration: none;
  }
}

.icon-round-first_page {
  .holly-icon();
  &:after {
    content: @holly-icon-round-first_page;
    text-decoration: none;
  }
}

.icon-round-flash_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-flash_off;
    text-decoration: none;
  }
}

.icon-round-flash_on {
  .holly-icon();
  &:after {
    content: @holly-icon-round-flash_on;
    text-decoration: none;
  }
}

.icon-round-folder_open {
  .holly-icon();
  &:after {
    content: @holly-icon-round-folder_open;
    text-decoration: none;
  }
}

.icon-round-folder_shared {
  .holly-icon();
  &:after {
    content: @holly-icon-round-folder_shared;
    text-decoration: none;
  }
}

.icon-round-folder_special {
  .holly-icon();
  &:after {
    content: @holly-icon-round-folder_special;
    text-decoration: none;
  }
}

.icon-round-folder {
  .holly-icon();
  &:after {
    content: @holly-icon-round-folder;
    text-decoration: none;
  }
}

.icon-round-format_color_reset {
  .holly-icon();
  &:after {
    content: @holly-icon-round-format_color_reset;
    text-decoration: none;
  }
}

.icon-round-forum {
  .holly-icon();
  &:after {
    content: @holly-icon-round-forum;
    text-decoration: none;
  }
}

.icon-round-forward_5 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-forward_5;
    text-decoration: none;
  }
}

.icon-round-forward_10 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-forward_10;
    text-decoration: none;
  }
}

.icon-round-forward_30 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-forward_30;
    text-decoration: none;
  }
}

.icon-round-gesture {
  .holly-icon();
  &:after {
    content: @holly-icon-round-gesture;
    text-decoration: none;
  }
}

.icon-round-grade {
  .holly-icon();
  &:after {
    content: @holly-icon-round-grade;
    text-decoration: none;
  }
}

.icon-round-grain-1 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-grain-1;
    text-decoration: none;
  }
}

.icon-round-grain {
  .holly-icon();
  &:after {
    content: @holly-icon-round-grain;
    text-decoration: none;
  }
}

.icon-round-grid_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-grid_off;
    text-decoration: none;
  }
}

.icon-round-grid_on {
  .holly-icon();
  &:after {
    content: @holly-icon-round-grid_on;
    text-decoration: none;
  }
}

.icon-round-group_add {
  .holly-icon();
  &:after {
    content: @holly-icon-round-group_add;
    text-decoration: none;
  }
}

.icon-round-help {
  .holly-icon();
  &:after {
    content: @holly-icon-round-help;
    text-decoration: none;
  }
}

.icon-round-highlight_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-highlight_off;
    text-decoration: none;
  }
}

.icon-round-home {
  .holly-icon();
  &:after {
    content: @holly-icon-round-home;
    text-decoration: none;
  }
}

.icon-round-hourglass_empty {
  .holly-icon();
  &:after {
    content: @holly-icon-round-hourglass_empty;
    text-decoration: none;
  }
}

.icon-round-hourglass_full {
  .holly-icon();
  &:after {
    content: @holly-icon-round-hourglass_full;
    text-decoration: none;
  }
}

.icon-round-image_search {
  .holly-icon();
  &:after {
    content: @holly-icon-round-image_search;
    text-decoration: none;
  }
}

.icon-round-indeterminate_check_box {
  .holly-icon();
  &:after {
    content: @holly-icon-round-indeterminate_check_box;
    text-decoration: none;
  }
}

.icon-round-insert_chart_outlined {
  .holly-icon();
  &:after {
    content: @holly-icon-round-insert_chart_outlined;
    text-decoration: none;
  }
}

.icon-round-insert_chart {
  .holly-icon();
  &:after {
    content: @holly-icon-round-insert_chart;
    text-decoration: none;
  }
}

.icon-round-insert_photo {
  .holly-icon();
  &:after {
    content: @holly-icon-round-insert_photo;
    text-decoration: none;
  }
}

.icon-round-invert_colors {
  .holly-icon();
  &:after {
    content: @holly-icon-round-invert_colors;
    text-decoration: none;
  }
}

.icon-round-keyboard_capslock {
  .holly-icon();
  &:after {
    content: @holly-icon-round-keyboard_capslock;
    text-decoration: none;
  }
}

.icon-round-language {
  .holly-icon();
  &:after {
    content: @holly-icon-round-language;
    text-decoration: none;
  }
}

.icon-round-last_page {
  .holly-icon();
  &:after {
    content: @holly-icon-round-last_page;
    text-decoration: none;
  }
}

.icon-round-line_style {
  .holly-icon();
  &:after {
    content: @holly-icon-round-line_style;
    text-decoration: none;
  }
}

.icon-round-linear_scale {
  .holly-icon();
  &:after {
    content: @holly-icon-round-linear_scale;
    text-decoration: none;
  }
}

.icon-round-link_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-link_off;
    text-decoration: none;
  }
}

.icon-round-link {
  .holly-icon();
  &:after {
    content: @holly-icon-round-link;
    text-decoration: none;
  }
}

.icon-round-list {
  .holly-icon();
  &:after {
    content: @holly-icon-round-list;
    text-decoration: none;
  }
}

.icon-round-local_movies {
  .holly-icon();
  &:after {
    content: @holly-icon-round-local_movies;
    text-decoration: none;
  }
}

.icon-round-location_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-location_off;
    text-decoration: none;
  }
}

.icon-round-location_on {
  .holly-icon();
  &:after {
    content: @holly-icon-round-location_on;
    text-decoration: none;
  }
}

.icon-round-location_searching {
  .holly-icon();
  &:after {
    content: @holly-icon-round-location_searching;
    text-decoration: none;
  }
}

.icon-round-filter_1 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-filter_1;
    text-decoration: none;
  }
}

.icon-round-filter_2 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-filter_2;
    text-decoration: none;
  }
}

.icon-round-filter_3 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-filter_3;
    text-decoration: none;
  }
}

.icon-round-filter_4 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-filter_4;
    text-decoration: none;
  }
}

.icon-round-filter_5 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-filter_5;
    text-decoration: none;
  }
}

.icon-round-filter_6 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-filter_6;
    text-decoration: none;
  }
}

.icon-round-filter_7 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-filter_7;
    text-decoration: none;
  }
}

.icon-round-filter_8 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-filter_8;
    text-decoration: none;
  }
}

.icon-round-filter_9 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-filter_9;
    text-decoration: none;
  }
}

.icon-round-filter_9_plus {
  .holly-icon();
  &:after {
    content: @holly-icon-round-filter_9_plus;
    text-decoration: none;
  }
}

.icon-round-looks_one {
  .holly-icon();
  &:after {
    content: @holly-icon-round-looks_one;
    text-decoration: none;
  }
}

.icon-round-looks_two {
  .holly-icon();
  &:after {
    content: @holly-icon-round-looks_two;
    text-decoration: none;
  }
}

.icon-icon-round-looks_3 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-looks_3;
    text-decoration: none;
  }
}

.icon-round-looks_4 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-looks_4;
    text-decoration: none;
  }
}

.icon-round-looks_5 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-looks_5;
    text-decoration: none;
  }
}

.icon-round-looks_6 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-looks_6;
    text-decoration: none;
  }
}

.icon-round-mail {
  .holly-icon();
  &:after {
    content: @holly-icon-round-mail;
    text-decoration: none;
  }
}

.icon-round-mic_none {
  .holly-icon();
  &:after {
    content: @holly-icon-round-mic_none;
    text-decoration: none;
  }
}

.icon-round-mic_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-mic_off;
    text-decoration: none;
  }
}

.icon-round-mic {
  .holly-icon();
  &:after {
    content: @holly-icon-round-mic;
    text-decoration: none;
  }
}

.icon-round-more_horiz {
  .holly-icon();
  &:after {
    content: @holly-icon-round-more_horiz;
    text-decoration: none;
  }
}

.icon-round-movie_filter {
  .holly-icon();
  &:after {
    content: @holly-icon-round-movie_filter;
    text-decoration: none;
  }
}

.icon-round-movie {
  .holly-icon();
  &:after {
    content: @holly-icon-round-movie;
    text-decoration: none;
  }
}

.icon-round-music_video {
  .holly-icon();
  &:after {
    content: @holly-icon-round-music_video;
    text-decoration: none;
  }
}

.icon-round-not_interested {
  .holly-icon();
  &:after {
    content: @holly-icon-round-not_interested;
    text-decoration: none;
  }
}

.icon-round-offline_bolt {
  .holly-icon();
  &:after {
    content: @holly-icon-round-offline_bolt;
    text-decoration: none;
  }
}

.icon-round-opacity {
  .holly-icon();
  &:after {
    content: @holly-icon-round-opacity;
    text-decoration: none;
  }
}

.icon-round-pause_circle_filled {
  .holly-icon();
  &:after {
    content: @holly-icon-round-pause_circle_filled;
    text-decoration: none;
  }
}

.icon-round-pause_circle_outline {
  .holly-icon();
  &:after {
    content: @holly-icon-round-pause_circle_outline;
    text-decoration: none;
  }
}

.icon-round-pause {
  .holly-icon();
  &:after {
    content: @holly-icon-round-pause;
    text-decoration: none;
  }
}

.icon-round-perm_contact_calendar {
  .holly-icon();
  &:after {
    content: @holly-icon-round-perm_contact_calendar;
    text-decoration: none;
  }
}

.icon-round-perm_media {
  .holly-icon();
  &:after {
    content: @holly-icon-round-perm_media;
    text-decoration: none;
  }
}

.icon-round-perm_phone_msg {
  .holly-icon();
  &:after {
    content: @holly-icon-round-perm_phone_msg;
    text-decoration: none;
  }
}

.icon-round-person_pin_circle {
  .holly-icon();
  &:after {
    content: @holly-icon-round-person_pin_circle;
    text-decoration: none;
  }
}

.icon-round-person_pin {
  .holly-icon();
  &:after {
    content: @holly-icon-round-person_pin;
    text-decoration: none;
  }
}

.icon-round-pin_drop {
  .holly-icon();
  &:after {
    content: @holly-icon-round-pin_drop;
    text-decoration: none;
  }
}

.icon-round-play_circle_filled_white {
  .holly-icon();
  &:after {
    content: @holly-icon-round-play_circle_filled_white;
    text-decoration: none;
  }
}

.icon-round-play_circle_outline {
  .holly-icon();
  &:after {
    content: @holly-icon-round-play_circle_outline;
    text-decoration: none;
  }
}

.icon-round-portrait {
  .holly-icon();
  &:after {
    content: @holly-icon-round-portrait;
    text-decoration: none;
  }
}

.icon-round-power_settings_new {
  .holly-icon();
  &:after {
    content: @holly-icon-round-power_settings_new;
    text-decoration: none;
  }
}

.icon-round-print {
  .holly-icon();
  &:after {
    content: @holly-icon-round-print;
    text-decoration: none;
  }
}

.icon-round-public {
  .holly-icon();
  &:after {
    content: @holly-icon-round-public;
    text-decoration: none;
  }
}

.icon-round-query_builder {
  .holly-icon();
  &:after {
    content: @holly-icon-round-query_builder;
    text-decoration: none;
  }
}

.icon-round-radio_button_checked {
  .holly-icon();
  &:after {
    content: @holly-icon-round-radio_button_checked;
    text-decoration: none;
  }
}

.icon-round-radio_button_unchecked {
  .holly-icon();
  &:after {
    content: @holly-icon-round-radio_button_unchecked;
    text-decoration: none;
  }
}

.icon-round-recent_actors {
  .holly-icon();
  &:after {
    content: @holly-icon-round-recent_actors;
    text-decoration: none;
  }
}

.icon-round-repeat {
  .holly-icon();
  &:after {
    content: @holly-icon-round-repeat;
    text-decoration: none;
  }
}

.icon-round-replay_5 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-replay_5;
    text-decoration: none;
  }
}

.icon-round-replay_10 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-replay_10;
    text-decoration: none;
  }
}

.icon-round-replay_30 {
  .holly-icon();
  &:after {
    content: @holly-icon-round-replay_30;
    text-decoration: none;
  }
}

.icon-round-report_problem {
  .holly-icon();
  &:after {
    content: @holly-icon-round-report_problem;
    text-decoration: none;
  }
}

.icon-round-scatter_plot {
  .holly-icon();
  &:after {
    content: @holly-icon-round-scatter_plot;
    text-decoration: none;
  }
}

.icon-round-send {
  .holly-icon();
  &:after {
    content: @holly-icon-round-send;
    text-decoration: none;
  }
}

.icon-round-settings_applications {
  .holly-icon();
  &:after {
    content: @holly-icon-round-settings_applications;
    text-decoration: none;
  }
}

.icon-round-settings_ethernet {
  .holly-icon();
  &:after {
    content: @holly-icon-round-settings_ethernet;
    text-decoration: none;
  }
}

.icon-round-settings_voice {
  .holly-icon();
  &:after {
    content: @holly-icon-round-settings_voice;
    text-decoration: none;
  }
}

.icon-round-sms_failed {
  .holly-icon();
  &:after {
    content: @holly-icon-round-sms_failed;
    text-decoration: none;
  }
}

.icon-round-sms {
  .holly-icon();
  &:after {
    content: @holly-icon-round-sms;
    text-decoration: none;
  }
}

.icon-round-speaker_notes_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-speaker_notes_off;
    text-decoration: none;
  }
}

.icon-round-speaker_notes {
  .holly-icon();
  &:after {
    content: @holly-icon-round-speaker_notes;
    text-decoration: none;
  }
}

.icon-round-stars {
  .holly-icon();
  &:after {
    content: @holly-icon-round-stars;
    text-decoration: none;
  }
}

.icon-round-style {
  .holly-icon();
  &:after {
    content: @holly-icon-round-style;
    text-decoration: none;
  }
}

.icon-round-supervised_user_circle {
  .holly-icon();
  &:after {
    content: @holly-icon-round-supervised_user_circle;
    text-decoration: none;
  }
}

.icon-round-swap_horiz {
  .holly-icon();
  &:after {
    content: @holly-icon-round-swap_horiz;
    text-decoration: none;
  }
}

.icon-round-swap_horizontal_circle {
  .holly-icon();
  &:after {
    content: @holly-icon-round-swap_horizontal_circle;
    text-decoration: none;
  }
}

.icon-round-swap_vert {
  .holly-icon();
  &:after {
    content: @holly-icon-round-swap_vert;
    text-decoration: none;
  }
}

.icon-round-swap_vertical_circle {
  .holly-icon();
  &:after {
    content: @holly-icon-round-swap_vertical_circle;
    text-decoration: none;
  }
}

.icon-round-timeline {
  .holly-icon();
  &:after {
    content: @holly-icon-round-timeline;
    text-decoration: none;
  }
}

.icon-round-toggle_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-toggle_off;
    text-decoration: none;
  }
}

.icon-round-toggle_on {
  .holly-icon();
  &:after {
    content: @holly-icon-round-toggle_on;
    text-decoration: none;
  }
}

.icon-round-translate {
  .holly-icon();
  &:after {
    content: @holly-icon-round-translate;
    text-decoration: none;
  }
}

.icon-round-trending_down {
  .holly-icon();
  &:after {
    content: @holly-icon-round-trending_down;
    text-decoration: none;
  }
}

.icon-round-trending_up {
  .holly-icon();
  &:after {
    content: @holly-icon-round-trending_up;
    text-decoration: none;
  }
}

.icon-round-unfold_less {
  .holly-icon();
  &:after {
    content: @holly-icon-round-unfold_less;
    text-decoration: none;
  }
}

.icon-round-unfold_more {
  .holly-icon();
  &:after {
    content: @holly-icon-round-unfold_more;
    text-decoration: none;
  }
}

.icon-round-view_carousel {
  .holly-icon();
  &:after {
    content: @holly-icon-round-view_carousel;
    text-decoration: none;
  }
}

.icon-round-visibility_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-visibility_off;
    text-decoration: none;
  }
}

.icon-round-visibility {
  .holly-icon();
  &:after {
    content: @holly-icon-round-visibility;
    text-decoration: none;
  }
}

.icon-round-volume_off {
  .holly-icon();
  &:after {
    content: @holly-icon-round-volume_off;
    text-decoration: none;
  }
}

.icon-round-volume_up {
  .holly-icon();
  &:after {
    content: @holly-icon-round-volume_up;
    text-decoration: none;
  }
}

.icon-round-wallpaper {
  .holly-icon();
  &:after {
    content: @holly-icon-round-wallpaper;
    text-decoration: none;
  }
}

.icon-round-warning {
  .holly-icon();
  &:after {
    content: @holly-icon-round-warning;
    text-decoration: none;
  }
}

.icon-round-watch_later {
  .holly-icon();
  &:after {
    content: @holly-icon-round-watch_later;
    text-decoration: none;
  }
}

.icon-round-youtube_searched_for {
  .holly-icon();
  &:after {
    content: @holly-icon-round-youtube_searched_for;
    text-decoration: none;
  }
}

.icon-round-zoom_out_map {
  .holly-icon();
  &:after {
    content: @holly-icon-round-zoom_out_map;
    text-decoration: none;
  }
}
