
////   Placeholders     ////

@radio-base-color : #d4d4d5;
@radio-checked-color : #000000;

@inputs-font:{
    .font(13);
   color: @input-font-color;
   font-weight: 500;
};



::-webkit-input-placeholder {
   
   font-weight: 300;
   color: @font-base-color;
}

:-moz-placeholder { /* Firefox 18- */
   font-weight: 300;
   color: @font-base-color;
}

::-moz-placeholder {  /* Firefox 19+ */
   font-weight: 300;
   color: @font-base-color;
}

:-ms-input-placeholder{  
   font-style: italic;
   color: @font-base-color;
}

.placeholdersjs{
font-style: italic;
color: @font-base-color;
}


///////////////////// INPUTS TEXT /////////////////////
label {
  color: #737373;
 .font(13);
 text-transform: none;
 font-weight: 300;
 float: left;
 margin-bottom: 5px;
}

input{
   margin: 5px 0 15px 0;
   padding: 2px 10px 2px 10px;
   display: block;
   float: none; 
   @inputs-font();
   border: 1px solid @inputs-border-color;
   .border-radius();
   box-sizing: border-box;
   .transition(border, 400ms);
   background-color: @input-bg;
   width: 100%;
   min-height: 40px;
   max-width: 400px;
   clear: both;
  @media @mobile, @tablet{
    max-width: 100%;
  };
}

input:focus,input:active {
   outline: none;
   background-color: @input-bg;
   border: 1px solid @brand-primary !important;
   .glow();
}

input:hover{
   border: 1px solid darken(@inputs-border-color, 25%);  
}
input:-webkit-autofill{
  background-color: @input-bg!important;
  color: @input-font-color!important;
  -webkit-box-shadow: 0 0 0px 1000px @input-bg inset;
}

///////// textarea //////////

textarea{
   margin: 5px 0 15px 0;
   padding: 10px;
   display: block;
   float: none; 
   color: @input-font-color;
   border: 1px solid @inputs-border-color;
   .border-radius();
   box-sizing: border-box;
   .transition(border, 400ms);
   .font(13);
   font-weight: 500;
   background-color: @input-bg;
   width: 100%;
   min-height: 40px;
   max-width: 400px;
}

textarea:focus,textarea:active {
   outline: none;
   background-color: @input-bg;
   border: 1px solid @brand-primary !important;
   .glow();
}

textarea:hover{
   border: 1px solid darken(@inputs-border-color, 25%);  
}
textarea:-webkit-autofill{
  background-color: @input-bg!important;
  color: @input-font-color!important;
  -webkit-box-shadow: 0 0 0 1000px @input-bg inset;
}







/////////////////// CHECBOX AND RADIO ///////////////////////////

// Defaulkt style for checkboxes
input[type="checkbox"]{
  padding: 0;
  max-width: 20px;
  float: left;
  margin-right: 15px;
  margin-top: 0;
  margin-bottom: 0;
  min-height: 20px;
    &:focus, &:checked{
      outline:none;
      box-shadow: none;
  }
}
    

.ui-checkbox{

  position: relative;
  display: inline-block;
  height: 17px;

  > input[type="checkbox"]{    
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0!important;
    margin: 0;
    outline: 0;
    z-index: 3;
    width: 17px;
    height: 17px;
  }

  > input[type="checkbox"] + label{
    .font(13);
    border: 1px solid @brand-primary;
      &:before{
      float: left;
      .holly-icon();
      content: '';
      text-decoration: none;
      font-size:14px;
      line-height: 14px;
      text-align: center;
      color: #000000;
      width: 14px;
      height: 14px;
      background: rgba(255, 255, 255, 0.7);

      &:focus, &:checked{
        background: @font-ultralight-color;
        outline:none;
      box-shadow: none;
      }
  }
}
input[type="checkbox"]:checked~label:before{
  border: 0;

  content: @holly-icon-baseline-done;
        background: @font-ultralight-color;
    @media @mobile, @tablet {
      background: @brand-second;
      color: @font-ultralight-color;
    }
  }
  input[type="checkbox"]:indeterminate~label:before{
    content: '-';
    background: @font-ultralight-color;
    font-weight: bold;
    font-size: 3rem;
    line-height: 8px;
  }
}


.ui-radio{

  position: relative;
  height: 20px;
  clear: both;
  line-height: 20px;
  margin-top: 5px;
  & label{
    .font(13);
    margin-left: 5px;
    vertical-align: middle;
  }
  & input[type="radio"]{    
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0!important;
    outline: 0;
    z-index: 3;
    width: 17px;
    height: 17px;
  }

  & input[type="radio"] + label{

      &:before{
      float: left;
      .holly-icon();
      content: @holly-icon-round-radio_button_unchecked;
      text-decoration: none;
      .font-size(18);
      line-height: 18px;
      text-align: center;
      color: @radio-base-color;
      width: 20px;
      height: 20px;
      background: #ffffff;
      margin-right: 0.5rem;
  }
}
input[type="radio"]:checked~label:before{
        content: @holly-icon-round-radio_button_checked;
        color: @radio-checked-color;
  }
}

///// SEARCH INPUT /////

input[type="search"] {
   .font(13);
   box-sizing: border-box;
   padding-right: 30px;
   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
   border: solid 1px #686868;

}

.filter-search{
   position: relative;
   float: right;
   top: -39px;
   border: none;
   background-color: transparent;
   margin: 0 !important;
   margin-right: 5px !important;
   .icon-search();
   &:after{
      font-size:15px;
      font-size:1.5rem;
      color: @font-dark-color;
   }
}

////////////////CALENDAR PICKER //////////////////


.glyphicon-calendar{
   .icon-Calendar();
}

i.calendar{
   .icon-Calendar();
   .font(12);
   float: right;
   border: none;
   background-color: #8a8a8a;
   margin: 0;
   position: absolute;
   top: 0;
   right: 0;
   width: 32px;
   height: 40px;
   text-align: center;
   line-height: 47px;
   &:after{
      .font(19);
      color: @font-ultralight-color;
   }
}


//////////////////// MISC ///////////////////

textarea{
   min-height: 150px;
}

.form-group{
   display: block;
   .clearfix();
}


///////////// SELECTS ///////////////

select{
  margin: 5px 0 15px 0;
  background: @input-bg;
  clear: both;
  display: block;
   width: 100%;
   min-height: 40px;
   max-width: 400px;
  padding: 0 @double-padding 0 @simple-padding;
  @inputs-font();
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: 97% 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABGdBTUEAALGPC/xhBQAAAFxJREFUCB1jLC8vX/X///9QBiyAkZFxNRMQZAMZr9HlQWIgOab29vbXQE42FgXZIDlmkMSRI0eu2djYaAOZIMwAMrqzs7MRxGYCESAAswpmNEQUjayoqAgFYWRhAMHyG+Jd5S2CAAAAAElFTkSuQmCC);
  .border-radius();
  height: 40px;
  min-width: 100px;
  overflow: auto;
  cursor: pointer;
  @media @mobile, @tablet{
    max-width: 100%;
   };
  &:focus{
    outline-color: fade(@brand-primary, 30%);
  }
}


