
@auth-text-color: #ffffff;

body.auth {
 margin: 0;
 height: 100%;
 min-height: 100%;
  width: 100%;
  vertical-align: middle;
  display: table;
  .bg-fixed(@body-error-bg, top, center,@bg-default-color);
  & .auth-container {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height:100%;
    text-align: center;
    background-color: transparent;
    padding: @triple-padding 0;
    overflow: auto;
    margin-right: -17px;
    & .auth-centered{
      width: 480px;
      position: relative;
      margin: auto;
      display: inline-block;
      vertical-align: middle;
      background: @brand-primary;
      padding: 0 @double-padding;
      @media @mobile{
        .col(10);
        float: none;
        display: inline-block;
        vertical-align: middle;
      };
      & .icon-baseline-chevron_right {
        position: absolute;
        bottom: 10px;
        right: 0;
        cursor: pointer;
      }

      & .toggle-access {
        cursor: pointer;
        &[data-toggle-state=visible] {
          & ~ .hiddenform {
            height: 320px;
            .transition(height,1s);
          }
        }
      }
      & .hiddenform {
        height: 0;
        overflow: hidden;
      }
      & .container-fluid {
        margin-bottom: @simple-padding;
        & form {
          & label {
            color: #ffffff;
          }
        }
        & #submit {
          width: 200px;
          margin-top: 60px;
        }
      }

    }

  }
  & .footerLogin {
    width: 480px;
    height: 100px;
    position: relative;
    margin: auto;
    background: white;
    padding: 20px 0px;
    @media @mobile {
      .col(10);
      float: none;
      display: inline-block;
      vertical-align: middle;
    }
  }

  & .form-login {
    overflow: hidden;
    .transition(height, 400ms);
    &[data-toggle-state=visible] {
      height: 270px;
    }
    & input {
      margin: 10px auto;
    }
    & .ui-checkbox {
      position: relative;
      right: 130px;
      margin-bottom: 10px;
    }
  }
  & .btn {
    border-radius: 10px;
    background: white;
    color: @brand-primary;
    width: 260px;
    display: block;
    margin: 30px auto;
    &:hover {
      background: @brand-primary;
      color: white;
    }
  }
  .login-logo {
    position: relative;
    height: 100%;
    & a{
      display: block;
      .bg-contain(@white-logo-png);
      height: 100%;
      max-width: 200px;
      float: none;
    }
    p {
      display: none;
      color: @auth-text-color;
      .font(14);
      margin-bottom: 20px;
    }

  }

////////////////////////// LANG SWITCH //////////////////////////////
  .lang-switch{
      position: absolute;
      display: inline-block;
      top: 10px;
      right: 10px;
      .font-size(14);
      float: right;
      background: @font-ultralight-color;
      opacity: 0.7;
      z-index: 1001;
    & li{
      list-style: none;
      display: block;
      float: left;
      margin: 0;
      text-transform: uppercase;
      a{
        color:@font-dark-color;
      }

      a:hover{

      color: @brand-second;
      text-decoration: none;
      font-weight: bold;
      }
    }

    .active{

      & a {
        font-weight: bold;
      }
    }
  }
////////////////////////// LANG SWITCH //////////////////////////////

  h2{
    text-align: center;
    text-transform: uppercase;
    color: @font-ultralight-color;
    font-family: 'museo300';
    .font(30);
    line-height: 45px;
    margin: 20px 0;
    font-weight: normal;
  }
  & .guest {
    .font(16);
    text-decoration: underline;
    color: #61658d;
    font-weight: bold;
    height: @double-padding;
    line-height: @double-padding;
  }
  h4 {
    text-align: center;
    text-transform: uppercase;
    color: @font-ultralight-color;
    .font(19);
    margin: 0;
    font-weight: 700;
  }
  .form-group {
    label {
      color: @font-dark-color;
     .font(14);
     text-transform: none;
     font-weight: normal;
    }

  }
// Pass recover link in login template
  .pass-recover {
    height: auto;
    margin: 15px 0 0 0;
    clear: both;
    position: relative;
    text-align: left;
    top: -130px;
    display: inline-block;
    float: left;

    a {
      color: @font-ultralight-color;
      font-size: 14px;
      text-decoration: underline;
      font-weight: bold;
      display: block;
    }

    a:hover{color: @brand-second;}

  }

  & #user_registration {
    & label {
      color: @font-ultralight-color;
    }
  }
// Registration link in login template
  .oauth-text{

  }



  /////////////////////// SIGIN UP /////////////////

  footer{
    background-color: transparent;
    margin-bottom:0;
    margin-top: 0;

   .contact {
      text-align: left;
      font-size: 12px;
      display: block;
      margin: 13px auto auto auto;
      color: #737373;
      text-decoration: none;
    }
  }
}



/////////////////////////// SIGN UP LINK


.request-access{
  text-align: center;
  .font(0);
  color: #ffffff;
  margin-bottom: 1rem;
  padding: 0 @simple-padding;
  .btn {
    width: 85%;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
  }
}

  .help-block{
     text-align: left;
     .font(13);
     text-transform: none;
     font-weight: 300;
    color: @font-ultralight-color;
  }
