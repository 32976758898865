////////////////CGU FIX TEMPLATE 

body.contact {
  & form[name="contact_request"] {
    & .form-group{
      border: 1px solid #000000;
      margin-top: -1px;
      position: relative;
      & label {
        display: none;
        &[for="multi_contact_request_subject"] + div {
          .icon-round-unfold_more();
          &:after {
            background: #8a8a8a;
            color: @font-ultralight-color;
            height: 40px;
            width: 40px;
            display: inline-block;
            position: absolute;
            right: 5px;
            bottom: 5px;
            line-height: 40px;
            font-size: 2.5rem;
            text-align: center;
            pointer-events: none;
          }
        }
      }
      & input, textarea {
        width: 100%;
        background: transparent;
        max-width: 100%;
        border: 0 !important;
        margin: 5px 0;
        box-shadow: none;
        padding-left: @double-padding;
        color: @font-darker-color;
        font-size: 15px;
        font-weight: bold;

        &:active {
          box-shadow: none;
          border:0 !important;
        }
      }
    }
    & button[type="submit"] {
      background: #eeeeee !important;
      color: @brand-second;
      border: 0 !important;
      width: inherit;
      padding: 0 40px;
      .font(16);
    }
  }
  & .contact-container {
    display: block;
    float: left;
    width: 100%;
    padding: 0 @simple-padding;
    & h1 {
      display: block;
      .font(26);
      color: @font-darker-color;
      border-bottom: 1px solid @font-light-color;
      padding-bottom: 1.2rem;
      margin: @double-padding 0;
      font-weight: 500;
    }

    & form {
      width: 70%;
      @media @mobile, @tablet {
        width: 100%;
      }
    }
    & .alert {
      line-height: 30px;
      width: 100%;
      max-width: inherit;
      margin: 0;
      color: #333;
      & ul {
        list-style: none;
        li {
          color: #333;
          font-size: 14px;
        }
      }
    }
    & .alert button.close {
      padding: 0;
      font-size: 0;
    }
  }
  & .navbar-nav{
    & .last a{
    }
  }
  & .g-recaptcha {
    float: right;
    position: absolute;
    top: 285px;
    right: @double-padding;
    @media @mobile, @tablet {
      top: 230px;
    }
  }
}