
@import "products-grids/list-not-cropped.less";
@import "products-grids/thumbnails.less";
@import "products-grids/block-not-cropped-no-captions.less";
@import "products-grids/list-cart-not-cropped.less";
@import "products-grids/list-cart.less";
@import "products-grids/block-not-cropped.less";



.items(@n){
  width: 100% / @n;
  display: inline-block;
  margin: 0 -1px;
}

//  ##########################  GROS COUP DE CLEAN A FAIRE   #############################################

.product-list{
 .container(fluid);
}
.product-simple-list{
.list-not-cropped();
}

.product-block-list{ 
	.block-not-cropped();
}


