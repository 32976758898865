
/**
 * Pagination
 */

.paginator-container{
  float: right;
  margin-top: @simple-padding;
  /*.transition(right, 0.45s);
  & #paginator {
    position: fixed;
    .icon-round-chevron_right();
    top: inherit;
    left: inherit;
    right: 0;
    bottom: 60px;
    margin: 0;
    line-height: 40px;
  }
  &[data-toggle-state=hidden] {
    right: -500px;
    width: 500px;
    & #paginator {
      .icon-round-chevron_left();
      line-height: 40px;
    }
  }*/
}

.pagination a, .pagination span {
  color: @font-light-color;
  font-weight: normal;
  font-size: 13px;
  vertical-align: middle;
  display: table-cell;
  
  
  & .sr-only{display:none;}
}

.pagination .active a {
  color: @font-dark-color;
  background-color: #D1D1D1;
  .border-radius();
  display: table-cell;
  &:hover {
    color: #fff;
    background-color: @brand-primary-alpha;
  }
}

.pagination .next {
.icon-baseline-chevron_right();
.font(13);

}

.pagination .previous {
  .icon-baseline-chevron_left();
  .font(13);
}

  .pagination {
    display: table;
    & li {
      .border-radius();
      list-style: none;
      vertical-align: middle;
      .container(40,left,40);
      display: table;
      text-align: center;
      &:hover {
        background-color: @brand-primary;
        & a{
          color: #fff;
          &:after{color: #fff;}
        }
      }
      & .disabled{
         &:hover {
        background-color: transparent;
        }
      }
    }
  }

