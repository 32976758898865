@font-face {
  font-family: 'museo300';
  src: url('../fonts/museosans-300-webfont.eot');
  src: url('../fonts/museosans-300-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/museosans-300-webfont.woff2') format('woff2'),
  url('../fonts/museosans-300-webfont.woff') format('woff'),
  url('../fonts/museosans-300-webfont.ttf') format('truetype'),
  url('../fonts/museosans-300-webfont.svg#museosans-300') format('svg');
  font-weight: normal;
  font-style: normal;
}