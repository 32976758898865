// ############################# Homepage Sidebar style

.home {

  .parade-container.vertical-padding {
    padding: 15px 30px;
    background: #fff;
    .actions {
      display: none;
    }
    .item {
      &:hover {
        .actions {
          display: block;
          cursor: pointer;
        }
      }
    }
  }


.sidebar {
.stories-sidebar{padding-top: 50px;}
    .soft-sidebar, .stories-sidebar {
      background: white;
      padding-left: 0;
      padding-top: 0;
      h3 {
        font-size: 18px;
        padding-bottom: 20px;
        font-weight: 100;
      }
    }

    a.gold-link {
      margin-top: 30px;
      text-decoration: none;
      font-size: 13px;
      font-weight: 400;
      color: #333a40;
      text-align: right;
      display: block;
      border-bottom: 1px solid @soft-border-color;
      border-top: 1px solid @soft-border-color;
      padding: 15px 0;
    }

    /*ul*/
    .visual-docs {
      list-style: none;
      margin: 0;
      padding: 0;

      /*li*/
      .document {
        // .make-row();
        overflow: hidden;
        color: @gray;
        position: relative;
        min-height: 50px;
        line-height: 50px;
        max-height: 50px;

        &:hover,
        &:active {
          .title {
            transition: width 0.5s ease;
            -webkit-transition: width 0.5s ease;
            -o-transition: width 0.5s ease;
            -moz-transition: width 0.5s ease;
            -ms-transition: width 0.5s ease;
            width: 50%;
          }
          .actions button {
            color: #fff;
            margin-top: -2px;
          }
        }
        &.selected {
          color: #fff;
          background-color: @brand-second;
          border-color: lighten(@brand-second, 10%);
          .actions button:hover {
            background-color: lighten(@brand-second, 10%);
          }
        }

        input[type="checkbox"] {
          font-size: 16px;
        }

        .thumbnail {
          height: 50px;
          .file-icon {
            display: inline-block;
            vertical-align: middle;
          }
        }

        .title {
          margin-left: 10px;
          line-height: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 75%;
          float: left;
          transition: width 0.5s ease;
          -webkit-transition: width 0.5s ease;
          -o-transition: width 0.5s ease;
          -moz-transition: width 0.5s ease;
          -ms-transition: width 0.5s ease;
        }
        .actions {
          text-align: right;
          padding: 0;
          float: left;
          // top: 50%;
          // margin-top: -20px;
          margin-top: -2px;
          button {
            color: lighten(@gray-light, 20%);
            background-color: transparent;
            margin-top: -2px; // quick clean QnD
            span {
              color: lighten(@gray-light, 20%);
            }
            border: 0;
            padding: 0;
            width: 30px;
            line-height: 60px;
          }
        }
      }
    }

  }

}
// ############################################### 

