.hide(@bkpoint){
	@media @bkpoint{
		display: none;

	}
	
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  display: none;
}




