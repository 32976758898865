
// @font-family

@import "../../ressources/fonts/font.roboto.less";
@import "../../ressources/fonts/font.museo.less";
@import "variables.colors.less";

// ******* Body font size  ******* // 

@font-xlarge:         18;
@font-large:          16;
@font-desktop:        15;
@font-tablet:         13;
@font-mobile:         12;

// ******* Header font size and weight  ******* // 

@h1:{
	font-family: @font-family-base;
	font-weight: 100;
	.font(48);
};

@h2:{
	font-family: @font-family-base;
	font-weight: 700;
	.font(22);
};

@h3:{font-family: @font-family-base;font-weight: 300;.font(22);};
@h4:{font-family: @font-family-base;font-weight: 300;.font(18);};
@h5:{font-family: @font-family-base;font-weight: 500;.font(16);margin:5px 0;color: @brand-primary;};

// ******* Font family  ******* // 

@client-font-family : arial;
@font-family-base: @client-font-family;
@secondary-font-family: @font-family-base;
@font-family-monospace : arial, helvetica;
@font-family-sans-serif:  @client-font-family;

/////   OLD ///////////////////////////////////////////////////////////////////

@font-size-h1:            48px;
@font-size-h2:            floor((@font-size-base * 2.15)); // ~30px
@font-size-h3:            ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4:            ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
@line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
@headings-font-family:    inherit;
@headings-font-weight:    100;
@headings-line-height:    1.1;
@headings-color:          inherit;
@font-size-base:          30px;
@font-size-large:         20px;
@font-size-small:         11px;


@close-text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);



////// FONT ////////////////////
@font-ultralight-color: #ffffff;
@font-light-color:      #b2b2b2;
@font-base-color :      #808080;
@font-dark-color:       #4c4c4c;
@font-darker-color: 	#333333;


// @client-font-family: @font-family-base;

// @font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
// @font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
// @font-family-base:        @font-family-sans-serif;

